import * as React from "react";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Firebase from "firebase/app";
import "firebase/firestore";

interface CityChangeDialogProps {
  open: boolean;
  onClose(): void;
}

// const useStyles = Mui.makeStyles({
//   avatar: {
//     backgroundColor: Mui.colors.blue[100],
//     color: Mui.colors.blue[600]
//   }
// });

const CityChangeDialog: React.FC<CityChangeDialogProps> = props => {
  const mallsRef = Firebase.firestore().collection("malls");

  const mallContext = React.useContext(App.Context.Mall.Mall);

  function handleListItemClick(city: string) {
    return function() {
      mallContext.subscribe(mallsRef, city);
      props.onClose();
    };
  }

  return (
    <Mui.Dialog onClose={props.onClose} open={props.open}>
      <Mui.DialogTitle>Choose a city</Mui.DialogTitle>
      <Mui.List>
        <Mui.Divider />
        {mallContext.cities.map(city => (
          <React.Fragment key={city}>
            <Mui.ListItem button onClick={handleListItemClick(city)}>
              <Mui.ListItemText primary={city} />
            </Mui.ListItem>
            <Mui.Divider />
          </React.Fragment>
        ))}
      </Mui.List>
    </Mui.Dialog>
  );
};

export default CityChangeDialog;
