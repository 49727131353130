import * as Mobx from "mobx";
import * as Model from "../models";
import * as Firebase from "firebase/app";
import "firebase/auth";

export class Malls {
  @Mobx.observable public malls: Model.Mall[] = [];
  @Mobx.observable public isFetching: boolean = true;

  @Mobx.action public subscribe(
    mallsRef: Firebase.firestore.CollectionReference
  ) {
    if (this.malls.length) {
      return;
    }

    const unsubscribe: () => void = mallsRef.onSnapshot(
      snapshot => {
        const malls: Model.Mall[] = snapshot.docs.map(doc => {
          return {
            mallId: doc.id,
            ...doc.data()
          } as Model.Mall;
        });
        this.malls = malls;
        this.isFetching = false;
      },
      (error: Error) => {
        console.error(error);
        unsubscribe();
      },
      () => unsubscribe()
    );
  }
}
