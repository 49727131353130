import * as Mobx from "mobx";
import * as Model from "../models";

export class Toast {
  @Mobx.observable public toast?: Model.Toast;

  @Mobx.action public showToast(toast: Model.Toast) {
    this.toast = toast;
  }

  @Mobx.action public hideToast() {
    this.toast = undefined;
  }
}
