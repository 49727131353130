/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Mobx from "mobx";
import * as Model from "../../models";
import * as Firebase from "firebase/app";
import "firebase/functions";

export class OrderHistory {
  @Mobx.observable public activeFilter: Model.Restaurant.Order.Filter = "all";
  @Mobx.observable public closedOrders: Model.Restaurant.Order.Order[] = [];
  @Mobx.observable public cancelledOrders: Model.Restaurant.Order.Order[] = [];
  @Mobx.observable public editItem?: Model.Restaurant.Order.Item.Edit;
  @Mobx.observable public fetchedData?: Boolean = false;
  @Mobx.observable public showClosedOrders?: Boolean = true;
  public ordersRef?: Firebase.firestore.CollectionReference;

  public lastClosedOrder?: Firebase.firestore.QueryDocumentSnapshot;
  public lastCancelledOrder?: Firebase.firestore.QueryDocumentSnapshot;
  @Mobx.observable public fetchMoreClosedOrder: boolean = false;
  @Mobx.observable public fetchMoreCancelledOrder: boolean = false;

  public recordsToFetch: number = 10;

  @Mobx.action public setClosedOrders(showClosed: Boolean) {
    this.showClosedOrders = showClosed;
  }

  @Mobx.action public loadMoreClosedOrders() {
    // Load the next paginated data
    this.fetchMoreClosedOrder = false;
    if (!this.ordersRef) {
      return;
    }
    this.getClosedOrdersPagination(this.ordersRef);
  }

  @Mobx.action public loadMoreCancelledOrders() {
    // Load the next paginated data
    this.fetchMoreCancelledOrder = false;
    if (!this.ordersRef) {
      return;
    }
    this.getCancelledOrderspaginate(this.ordersRef);
  }

  @Mobx.action public subscribe(
    ordersRef: Firebase.firestore.CollectionReference,
    restaurantId: string
  ) {
    if (this.fetchedData) {
      return;
    }
    this.getClosedOrders(ordersRef, restaurantId);
    this.getCancelledOrders(ordersRef, restaurantId);
  }

  getClosedOrders = (
    ordersRef: Firebase.firestore.CollectionReference,
    restaurantId: string
  ) => {
    ordersRef
      .where("restaurantId", "==", restaurantId)
      .where("statusUpdates.closed", "==", true)
      .where("cancelled", "==", false)
      .orderBy("createdAt", "desc")
      .limit(this.recordsToFetch)
      .get()
      .then(
        querySnapshot => {
          this.closedOrders = [];
          querySnapshot.docs.forEach(
            (
              docSnapshot: Firebase.firestore.QueryDocumentSnapshot,
              index: number
            ) => {
              const orderData = docSnapshot.data() as Model.Restaurant.Order.Order;
              orderData.orderId = docSnapshot.id;
              this.closedOrders!.push(orderData);
              this.lastClosedOrder = docSnapshot;
              index == this.recordsToFetch - 1
                ? (this.fetchMoreClosedOrder = true)
                : (this.fetchMoreClosedOrder = false);
            }
          );
          this.fetchedData = true;
        },
        (error: Error) => {
          console.error(error);
          this.closedOrders = [];
        }
      );
  };

  getCancelledOrders = (
    ordersRef: Firebase.firestore.CollectionReference,
    restaurantId: string
  ) => {
    ordersRef
      .where("restaurantId", "==", restaurantId)
      .where("cancelled", "==", true)
      .limit(this.recordsToFetch)
      .get()
      .then(
        querySnapshot => {
          this.cancelledOrders = [];
          querySnapshot.docs.forEach(
            (docSnapshot: Firebase.firestore.QueryDocumentSnapshot, index) => {
              const orderData = docSnapshot.data() as Model.Restaurant.Order.Order;
              orderData.orderId = docSnapshot.id;
              this.cancelledOrders!.push(orderData);
              this.lastCancelledOrder = docSnapshot;
              // Note: then below code will identify if more data are available to fetch ]
              index == this.recordsToFetch - 1
                ? (this.fetchMoreCancelledOrder = true)
                : (this.fetchMoreCancelledOrder = false);
            }
          );
        },
        (error: Error) => {
          console.error(error);
          this.cancelledOrders = [];
        }
      );
  };
  //TODO call this when pagination is implemented
  getClosedOrdersPagination = (
    ordersRef: Firebase.firestore.CollectionReference
  ) => {
    ordersRef
      .where("statusUpdates.closed", "==", true)
      .where("cancelled", "==", false)
      .orderBy("createdAt", "desc")
      .limit(this.recordsToFetch)
      .startAfter(this.lastClosedOrder)
      .get()
      .then(
        querySnapshot => {
          querySnapshot.docs.forEach(
            (docSnapshot: Firebase.firestore.QueryDocumentSnapshot, index) => {
              const orderData = docSnapshot.data() as Model.Restaurant.Order.Order;
              orderData.orderId = docSnapshot.id;
              this.closedOrders!.push(orderData);
              this.lastClosedOrder = docSnapshot;
              index == this.recordsToFetch - 1
                ? (this.fetchMoreClosedOrder = true)
                : (this.fetchMoreClosedOrder = false);
            }
          );
        },
        (error: Error) => {
          console.error(error);
        }
      );
  };

  //TODO call this when pagination is implemented
  getCancelledOrderspaginate = (
    ordersRef: Firebase.firestore.CollectionReference
  ) => {
    ordersRef
      .where("cancelled", "==", true)
      .limit(this.recordsToFetch)
      .startAfter(this.lastCancelledOrder)
      .get()
      .then(
        querySnapshot => {
          querySnapshot.docs.forEach(
            (docSnapshot: Firebase.firestore.QueryDocumentSnapshot, index) => {
              const orderData = docSnapshot.data() as Model.Restaurant.Order.Order;
              orderData.orderId = docSnapshot.id;
              this.cancelledOrders.push(orderData);
              this.lastCancelledOrder = docSnapshot;
              index == this.recordsToFetch - 1
                ? (this.fetchMoreCancelledOrder = true)
                : (this.fetchMoreCancelledOrder = false);
            }
          );
        },
        (error: Error) => {
          console.error(error);
        }
      );
  };

  public getOrderItemDescription({
    addOn,
    required,
    instruction
  }: {
    addOn?: Model.Restaurant.Order.Item.Option[];
    required?: Model.Restaurant.Order.Item.Option[];
    instruction?: string;
  }): string {
    return [...required, ...addOn, instruction]
      .filter(value => value)
      .map(option => {
        if (typeof option === "string") {
          return option;
        }
        if (!option) {
          return undefined;
        }
        return option.name;
      })
      .join(", ");
  }

  public getItemCustomizationsFromOrderItem(
    orderItem: Model.Restaurant.Order.Item.Item
  ): Map<string, Map<string, Model.Restaurant.Order.Item.Option>> | undefined {
    if (!orderItem.addOn && !orderItem.required) {
      return undefined;
    }

    const customizations: Map<
      string,
      Map<string, Model.Restaurant.Order.Item.Option>
    > = new Map();

    [...orderItem.addOn, ...orderItem.required].forEach(option => {
      const customizationId = (option.addOnCustomizationId ||
        option.requiredCustomizationId) as string;
      const checkedOptionsAlreadyExists = customizations.get(customizationId);

      if (!checkedOptionsAlreadyExists) {
        const checkedOptions = new Map();
        checkedOptions.set(option.optionId as string, option);
        customizations.set(customizationId, checkedOptions);
      }

      if (checkedOptionsAlreadyExists) {
        checkedOptionsAlreadyExists.set(option.optionId as string, option);
        customizations.set(customizationId, checkedOptionsAlreadyExists);
      }
    });

    return customizations;
  }
}
