import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Styled from "../../styles";
import { withStyles } from "@material-ui/core/styles";

interface StaffMenuItemTypeProps {}

const StaffMenuItemType: React.FC<StaffMenuItemTypeProps> = Mobx.observer((props) => {
  const userStore = React.useContext(App.Context.User.User);
  const menuItemStore = React.useContext(App.Context.Restaurant.Menu.Item.Item);
  const menuItemEditStore = React.useContext(App.Context.Restaurant.Menu.Item.Edit);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!menuItemEditStore.isEditable || !menuItemEditStore.item) {
      return;
    }
    // menuItemEditStore.toggleTags();
    menuItemEditStore.saveItemType(event.target.value as string);
  };

  const getTypeDetail = (): string => {
    if (menuItemEditStore.isEditable && menuItemEditStore.item) {
      return menuItemEditStore.item.type || "";
    } else if (menuItemStore.menuItem && menuItemStore.menuItem.type) {
      return menuItemStore.menuItem.type || "";
    }
    return "";
  };

  return (
    <>
      {userStore.isStaff && ((menuItemEditStore.isEditable && menuItemEditStore.item) || menuItemStore.menuItem) && (
        <>
          <Mui.ExpansionPanel expanded={true}>
            <Styled.Mui.ExpansionPanelSummary>
              <Mui.Grid container justify="space-between" alignItems="center">
                <Mui.Grid container justify="flex-start" xs={2} sm={2}>
                  <Mui.Typography variant="h6">Type</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid direction="row" justify="flex-end" container xs={10} sm={10}>
                  <Mui.FormControl>
                    <Mui.InputLabel id="demo-simple-select-label">Type</Mui.InputLabel>
                    <Mui.Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={getTypeDetail()}
                      onChange={handleChange}
                      fullWidth
                    >
                      <Mui.MenuItem value={""}>None</Mui.MenuItem>
                      <Mui.MenuItem value={"food"}>Food</Mui.MenuItem>
                      <Mui.MenuItem value={"bar"}>Bar</Mui.MenuItem>
                    </Mui.Select>
                  </Mui.FormControl>
                </Mui.Grid>
              </Mui.Grid>
            </Styled.Mui.ExpansionPanelSummary>
          </Mui.ExpansionPanel>
        </>
      )}
    </>
  );
});

export default StaffMenuItemType;
