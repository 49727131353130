import * as Firebase from "firebase/app";

export async function isStaff(user: Firebase.User | null): Promise<boolean> {
  if (!user) {
    return false;
  }

  const token = await user.getIdTokenResult();
  return token.claims.staff ? true : false;
}

export async function isAdmin(user: Firebase.User | null): Promise<boolean> {
  if (!user) {
    return false;
  }

  const token = await user.getIdTokenResult();
  console.log("claims",token.claims);
  return token.claims.admin ? true : false;
}
