import * as React from "react";
import * as Styled from "../../styles";

export enum FIXED_FOOTER_BACKGROUND {
  TRANSPARENT = "TRANSPARENT",
  WHITE = "WHITE"
}

export enum FIXED_FOOTER_PADDING {
  DEFAULT = "DEFAULT",
  LARGE = "LARGE"
}

interface FixedFooterProps {
  background?: FIXED_FOOTER_BACKGROUND;
  padding?: FIXED_FOOTER_PADDING;
}

const FixedFooter: React.FC<FixedFooterProps> = props => {
  return (
    <>
      {props.padding === FIXED_FOOTER_PADDING.LARGE ? (
        <Styled.Mui.ContainerLargePaddingBottom maxWidth="sm">
          <FixedFooterBackground {...props} />
        </Styled.Mui.ContainerLargePaddingBottom>
      ) : (
        <Styled.Mui.ContainerPaddingBottom maxWidth="sm">
          <FixedFooterBackground {...props} />
        </Styled.Mui.ContainerPaddingBottom>
      )}
    </>
  );
};

const FixedFooterBackground: React.FC<Omit<
  FixedFooterProps,
  "padding"
>> = props => {
  return (
    <>
      {props.background === FIXED_FOOTER_BACKGROUND.TRANSPARENT ? (
        <Styled.Footer.Fixed>{props.children}</Styled.Footer.Fixed>
      ) : (
        <Styled.Footer.FixedWhiteBackground>
          {props.children}
        </Styled.Footer.FixedWhiteBackground>
      )}
    </>
  );
};

FixedFooter.defaultProps = {
  background: FIXED_FOOTER_BACKGROUND.TRANSPARENT,
  padding: FIXED_FOOTER_PADDING.DEFAULT
};

export default FixedFooter;
