import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Styled from "../../styles";
import { withStyles } from "@material-ui/core/styles";

interface StaffMenuItemHideProps {}

const StaffMenuItemHide: React.FC<StaffMenuItemHideProps> = Mobx.observer((props) => {
  const userStore = React.useContext(App.Context.User.User);
  const menuItemStore = React.useContext(App.Context.Restaurant.Menu.Item.Item);
  const menuItemEditStore = React.useContext(App.Context.Restaurant.Menu.Item.Edit);

  function handleChange() {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      if (!menuItemEditStore.isEditable || !menuItemEditStore.item) {
        return;
      }

      menuItemEditStore.toggleHide();
    };
  }

  const CustomSwitch = withStyles({
    switchBase: {
      color: Mui.colors.red[500],
      "&$checked": {
        color: Mui.colors.green[500],
      },
      "&$checked + $track": {
        backgroundColor: Mui.colors.green[500],
      },
    },
    checked: {},
    track: { backgroundColor: Mui.colors.red[500] },
  })(Mui.Switch);

  const checkHide = (): boolean => {
    if (menuItemStore.menuItem) {
      if (menuItemStore.menuItem.hide) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const getTypeDetail = (): string => {
    if (menuItemEditStore.item && menuItemEditStore.item.type) {
      console.log(menuItemEditStore.item.type);
      return menuItemEditStore.item.type;
    }
    return "";
  };

  return (
    <>
      {userStore.isStaff && ((menuItemEditStore.isEditable && menuItemEditStore.item) || menuItemStore.menuItem) && (
        <>
          <Mui.ExpansionPanel expanded={true}>
            <Styled.Mui.ExpansionPanelSummary>
              <Mui.Grid container justify="space-between" alignItems="center">
                <Mui.Typography variant="h6">Hide Item</Mui.Typography>
              </Mui.Grid>
              <CustomSwitch
                checked={
                  menuItemEditStore.isEditable && menuItemEditStore.item
                    ? menuItemEditStore.item.hide || false
                    : (menuItemStore.menuItem && menuItemStore.menuItem.hide) || false || false
                }
                onChange={handleChange()}
              />
            </Styled.Mui.ExpansionPanelSummary>
          </Mui.ExpansionPanel>
        </>
      )}
    </>
  );
});

export default StaffMenuItemHide;
