import * as React from "react";
import * as Router from "react-router-dom";
import * as ReactFire from "reactfire";
import * as Component from "../components";
import * as Layout from "../layouts";
import * as View from "../views";

const Global: React.FC = () => {
  return (
    <>
      <Router.BrowserRouter>
        <Router.Switch>
          <Router.Route exact path="/staff/login">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev>
                  <View.StaffLogin />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/restaurant/:restaurantId/item/:menuItemId/edit">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev>
                  <View.EditItem />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/restaurant/:restaurantId/item/:menuItemId/add">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev>
                  <View.EditItem />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/engagementLayer">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev>
                  <View.EngagementLayer />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/uploadRestaurantData">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev>
                  <View.UploadRestaurant />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/restaurant/:restaurantId/menu">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev variant={Layout.VARIANT.MASTER}>
                  <View.Menu />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/metadata/-L4AoY15n5FYrWO1_irb/1">
            <Router.Redirect to="/restaurant/azq46xERGn0iJdTuDUop/menu" />
          </Router.Route>

          <Router.Route exact path="/restaurant/:restaurantId/settings">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev variant={Layout.VARIANT.MASTER}>
                  <View.Settings />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/restaurant/:restaurantId/room/:roomNumber">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev variant={Layout.VARIANT.MASTER}>
                  <View.QrLinkProcessor />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/mall/:mallId">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev variant={Layout.VARIANT.MASTER}>
                  <View.Restaurants />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/user/account">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Component.PageLoadingIndicator />}>
                <Layout.Dev variant={Layout.VARIANT.MASTER}>
                  <View.Account />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev variant={Layout.VARIANT.MASTER}>
                  <View.Home />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/restaurant/:restaurantId/item/:menuItemId">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev>
                  <View.Item />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/order">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev>
                  <View.Order />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/restaurant/:restaurantId/orders">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev variant={Layout.VARIANT.MASTER}>
                  <View.Orders />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/restaurant/:restaurantId/history">
            <React.Suspense fallback={<Layout.Dev />}>
              <ReactFire.AuthCheck fallback={<Router.Redirect to="/404" />}>
                <Layout.Dev variant={Layout.VARIANT.MASTER}>
                  <View.OrdersHistory />
                </Layout.Dev>
              </ReactFire.AuthCheck>
            </React.Suspense>
          </Router.Route>

          <Router.Route exact path="/404">
            <Layout.Dev>
              <View.PageNotFound />
            </Layout.Dev>
          </Router.Route>

          <Router.Route path="*">
            <Router.Redirect to="/404" />
          </Router.Route>
        </Router.Switch>
      </Router.BrowserRouter>
    </>
  );
};

export default Global;
