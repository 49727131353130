import * as React from "react";
import * as ReactGA from "react-ga";

const withTracker = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options: ReactGA.FieldsObject = {}
) => {
  const trackPage = (page: string) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  return (props: P) => {
    React.useEffect(() => {
      trackPage(window.location.pathname);
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export default withTracker;
