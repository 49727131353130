/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Firebase from "firebase/app";
import "firebase/functions";
import * as Mobx from "mobx";
import * as Model from "../../models";

export class Order {
  @Mobx.observable public activeFilter: Model.Restaurant.Order.Filter = "all";
  @Mobx.observable public activeOrders?: Model.Restaurant.Order.Order[] = [];
  @Mobx.observable public editItem?: Model.Restaurant.Order.Item.Edit;
  @Mobx.observable public fetchedData?: Boolean = false;

  private acceptOrderFunction = Firebase.functions().httpsCallable(
    "acceptOrder"
  );
  private updateOrderReadyFunction = Firebase.functions().httpsCallable(
    "updateOrderReady"
  );
  private closeOrderFunction = Firebase.functions().httpsCallable("closeOrder");

  public orderStatus = {
    new: "new",
    paid: "paid",
    created: "created",
    accepted: "accepted",
    ready: "ready",
    closed: "closed",
  };

  private notificationTone = new Audio("/media/for-sure.mp3");

  @Mobx.action public setActiveFilter(filter: Model.Restaurant.Order.Filter) {
    this.activeFilter = filter;
  }

  @Mobx.computed public get newOrders(): Model.Restaurant.Order.Order[] {
    if (!this.activeOrders) {
      return [];
    }

    const newOrders = this.activeOrders.filter(
      (order) => order.status && order.status === "created"
    );

    return newOrders;
  }

  @Mobx.computed public get allOrders(): Model.Restaurant.Order.Order[] {
    if (!this.activeOrders) {
      return [];
    }

    const allOrders = this.activeOrders.filter(
      (order) => order.status && order.status !== "created"
    );

    return allOrders;
  }

  @Mobx.computed public get preparingOrders(): Model.Restaurant.Order.Order[] {
    if (!this.activeOrders) {
      return [];
    }

    const preparingOrders = this.activeOrders.filter(
      (order) => order.status && order.status === "accepted"
    );

    return preparingOrders;
  }

  @Mobx.computed get readyOrders(): Model.Restaurant.Order.Order[] {
    if (!this.activeOrders) {
      return [];
    }

    const readyOrders = this.activeOrders.filter(
      (order) => order.status && order.status === "ready"
    );

    return readyOrders;
  }

  @Mobx.action public subscribe(
    ordersRef: Firebase.firestore.CollectionReference,
    restaurantId: string
  ) {
    if (this.fetchedData) {
      return;
    }
    this.getActiveOrders(ordersRef, restaurantId);
  }

  getActiveOrders = (
    ordersRef: Firebase.firestore.CollectionReference,
    restaurantId: string
  ) => {
    ordersRef
      .where("restaurantId", "==", restaurantId)
      // .where("statusUpdates.paid", "==", true)
      .where("statusUpdates.closed", "==", false)
      .where("cancelled", "==", false)
      .orderBy("createdAt", "desc")
      .onSnapshot(
        (querySnapshot) => {
          this.activeOrders = [];
          querySnapshot.docs.forEach(
            (docSnapshot: Firebase.firestore.QueryDocumentSnapshot) => {
              const orderData = docSnapshot.data() as Model.Restaurant.Order.Order;
              orderData.orderId = docSnapshot.id;
              this.activeOrders!.push(orderData);
            }
          );
          this.playNotificationTone();
          this.fetchedData = true;
        },
        (error: Error) => {
          console.error(error);
          this.activeOrders = [];
        }
      );
  };

  public playNotificationTone() {
    if (this.newOrders.length) {
      this.notificationTone.loop = true;
      this.notificationTone.play();
    }
  }

  public pauseNotificationTone(
    order: Model.Restaurant.Order.Order | undefined
  ) {
    if (!order || !order.orderId) {
      return;
    }

    if (this.newOrders.some((newOrder) => newOrder.orderId === order.orderId)) {
      this.notificationTone.pause();
    }
  }

  @Mobx.action public acceptOrder = async (
    orderDetails: Model.Restaurant.Order.Order
  ) => {
    if (
      !(
        orderDetails &&
        orderDetails.orderId &&
        orderDetails.restaurantId &&
        orderDetails.orderTime
      )
    ) {
      return;
    }
    const result: {
      data: { success: boolean; error: boolean; errorMessgae: string };
    } = await this.acceptOrderFunction({
      orderId: orderDetails.orderId,
      orderTime: orderDetails.orderTime,
      restaurantId: orderDetails.restaurantId,
    });
  };

  @Mobx.action public updateOrderReady = async (
    orderDetails: Model.Restaurant.Order.Order
  ) => {
    if (!(orderDetails && orderDetails.orderId && orderDetails.restaurantId)) {
      return;
    }
    const result: {
      data: { success: boolean; error: boolean; errorMessgae: string };
    } = await this.updateOrderReadyFunction({
      orderId: orderDetails.orderId,
      restaurantId: orderDetails.restaurantId,
    });
  };

  @Mobx.action public closeOrder = async (
    orderDetails: Model.Restaurant.Order.Order
  ) => {
    if (!(orderDetails && orderDetails.orderId && orderDetails.restaurantId)) {
      return;
    }
    const result: {
      data: { success: boolean; error: boolean; errorMessgae: string };
    } = await this.closeOrderFunction({
      orderId: orderDetails.orderId,
      restaurantId: orderDetails.restaurantId,
    });
  };

  public getOrderItemDescription({
    addOn,
    required,
    instruction,
  }: {
    addOn?: Model.Restaurant.Order.Item.Option[];
    required?: Model.Restaurant.Order.Item.Option[];
    instruction?: string;
  }): string {
    return [...required, ...addOn, instruction]
      .filter((value) => value)
      .map((option) => {
        if (typeof option === "string") {
          return option;
        }
        if (!option) {
          return undefined;
        }
        return option.name;
      })
      .join(", ");
  }

  public getItemCustomizationsFromOrderItem(
    orderItem: Model.Restaurant.Order.Item.Item
  ): Map<string, Map<string, Model.Restaurant.Order.Item.Option>> | undefined {
    if (!orderItem.addOn && !orderItem.required) {
      return undefined;
    }

    const customizations: Map<
      string,
      Map<string, Model.Restaurant.Order.Item.Option>
    > = new Map();

    [...orderItem.addOn, ...orderItem.required].forEach((option) => {
      const customizationId = (option.addOnCustomizationId ||
        option.requiredCustomizationId) as string;
      const checkedOptionsAlreadyExists = customizations.get(customizationId);

      if (!checkedOptionsAlreadyExists) {
        const checkedOptions = new Map();
        checkedOptions.set(option.optionId as string, option);
        customizations.set(customizationId, checkedOptions);
      }

      if (checkedOptionsAlreadyExists) {
        checkedOptionsAlreadyExists.set(option.optionId as string, option);
        customizations.set(customizationId, checkedOptionsAlreadyExists);
      }
    });

    return customizations;
  }
}
