import * as Mui from "@material-ui/core";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "../../app";
import * as Model from "../../models";
import * as Styled from "../../styles";

interface StaffCloseOrderListItemButtonProps {
  order: Model.Restaurant.Order.Order;
}

const StaffCloseOrderListItemButton: React.FC<StaffCloseOrderListItemButtonProps> = (
  props
) => {
  const userStore = React.useContext(App.Context.User.User);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const orderStore = React.useContext(App.Context.Restaurant.Order);

  function handleClick(order: Model.Restaurant.Order.Order) {
    return function () {
      orderStore.order = order;
    };
  }

  return (
    <>
      {userStore.isStaff &&
        restaurantStore.restaurant &&
        props.order.status &&
        props.order.status === "ready" && (
          <>
            <Mui.Divider />
            <Styled.Mui.ListItemNoPadding>
              <Mui.Button
                color="primary"
                fullWidth
                onClick={handleClick(props.order)}
                component={Router.Link}
                to={"/order"}
              >
                Order Served
              </Mui.Button>
            </Styled.Mui.ListItemNoPadding>
          </>
        )}
    </>
  );
};

export default StaffCloseOrderListItemButton;
