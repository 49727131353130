import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "../../app";

enum TITLE_VARIANT {
  DEFAULT = "DEFAULT",
  ORDER = "ORDER",
}

interface TitleProps {
  variant?: TITLE_VARIANT;
}

const Title: React.FC<TitleProps> = Mobx.observer((props) => {
  const orderStore = React.useContext(App.Context.Restaurant.Order);
  const theme = Mui.useTheme();

  return (
    <>
      <Mui.Typography variant="h6">
        {props.variant === TITLE_VARIANT.ORDER
          ? `# ${orderStore.order!.orderId!.slice(-5).toUpperCase()}`
          : "My Order"}
      </Mui.Typography>

      {props.variant === TITLE_VARIANT.ORDER && (
        <Mui.Typography
          variant="body2"
          style={{ marginTop: theme.spacing(-1) }}
        >
          {new Date(orderStore.order!.createdAt!.seconds * 1000).toDateString()}
        </Mui.Typography>
      )}
    </>
  );
});

Title.defaultProps = {
  variant: TITLE_VARIANT.DEFAULT,
};

interface OrderAppBarTitleProps {}

const OrderAppBarTitle: React.FC<OrderAppBarTitleProps> = Mobx.observer(
  (props) => {
    const orderStore = React.useContext(App.Context.Restaurant.Order);

    const { pathname } = Router.useLocation();
    const isOrderPage =
      !pathname.includes("/orders") && pathname.includes("/order");

    return (
      <>
        {isOrderPage && !orderStore.order && <Title />}
        {isOrderPage && orderStore.order && !orderStore.order.orderId && (
          <Title />
        )}
        {isOrderPage && orderStore.order && orderStore.order.orderId && (
          <Title variant={TITLE_VARIANT.ORDER} />
        )}
      </>
    );
  }
);

export default OrderAppBarTitle;
