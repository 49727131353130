import * as Mui from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as MuiIcon from "@material-ui/icons";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as App from "../../app";
import * as Component from "../../components";

interface OrderCouponCodeProps {}

const OrderCouponCode: React.FC<OrderCouponCodeProps> = Mobx.observer(
  (props) => {
    const userStore = React.useContext(App.Context.User.User);
    const orderStore = React.useContext(App.Context.Restaurant.Order);

    const [openUserSignInDialog, setOpenUserSignInDialog] = React.useState<
      boolean
    >(false);
    const [openMobileOTPDialog, setOpenMobileOTPDialog] = React.useState<
      boolean
    >(false);

    async function handleLoginClick() {
      setOpenUserSignInDialog(true);
    }

    function handleUserSignInDialogClose(showOTP?: boolean) {
      setOpenUserSignInDialog(false);
      if (showOTP) {
        setOpenMobileOTPDialog(true);
        return;
      }
    }

    function handleMobileOTPDialogClose() {
      setOpenMobileOTPDialog(false);
    }

    const removeCoupon = () => {
      orderStore.removeCoupon();
    };
    const applyCoupon = async () => {
      const result = await orderStore.applyCoupon();
      if (!result) {
        return;
      }
      if (result.valid) {
        setDialogHeader("Hooray!!");
        setDialogBody(`Discount of ${result.discount} is successfully applied`);
      } else {
        setDialogHeader("Oops!!");
        setDialogBody(`${result.reason}`);
      }
      handleClickOpen();
    };

    const [open, setOpen] = React.useState(false);
    const [dialogBody, setDialogBody] = React.useState("");
    const [dialogHeader, setDialogHeader] = React.useState("");

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          padding: "2px 4px",
          display: "flex",
          alignItems: "center",
        },
        input: {
          marginLeft: theme.spacing(1),
          flex: 1,
        },
        iconButton: {
          padding: 10,
        },
        divider: {
          height: 28,
          margin: 4,
        },
      })
    );
    const classes = useStyles();

    return (
      <>
        <Component.UserSignInDialog
          open={openUserSignInDialog}
          handleClose={handleUserSignInDialogClose}
        />
        <Component.MobileOTPDialog
          open={openMobileOTPDialog}
          handleClose={handleMobileOTPDialogClose}
        />
        {!userStore.isStaff &&
          orderStore.order &&
          !orderStore.order.orderId &&
          orderStore.order.items &&
          orderStore.order.items.length > 0 && (
            <Mui.Paper component="form" className={classes.root}>
              <Mui.IconButton className={classes.iconButton} aria-label="menu">
                <MuiIcon.LocalOffer />
              </Mui.IconButton>
              {orderStore.user && orderStore.user.phoneNumber ? (
                <Mui.InputBase
                  className={classes.input}
                  placeholder=" Apply Coupon Code"
                  inputProps={{ "aria-label": "Apply Coupon Code" }}
                  onChange={orderStore.handleCouponInput}
                  disabled={orderStore.order.isDiscountApplied}
                />
              ) : (
                <Mui.InputBase
                  className={classes.input}
                  placeholder=" Login to apply Coupon Code"
                  inputProps={{ "aria-label": "Login to apply Coupon Code" }}
                  disabled={true}
                />
              )}

              <Mui.Divider className={classes.divider} orientation="vertical" />
              {orderStore.user && orderStore.user.phoneNumber ? (
                !orderStore.order.isDiscountApplied ? (
                  <Mui.Button
                    disabled={
                      !(
                        orderStore.couponCode &&
                        orderStore.couponCode.length > 3
                      )
                    }
                    onClick={applyCoupon}
                    color="primary"
                  >
                    Apply
                  </Mui.Button>
                ) : (
                  <Mui.IconButton
                    className={classes.iconButton}
                    aria-label="menu"
                    onClick={removeCoupon}
                  >
                    <MuiIcon.Delete />
                  </Mui.IconButton>
                )
              ) : (
                <Mui.Button onClick={handleLoginClick} color="primary">
                  Login
                </Mui.Button>
              )}
            </Mui.Paper>
          )}
        <Mui.Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Mui.DialogTitle id="alert-dialog-title">
            {dialogHeader}
          </Mui.DialogTitle>
          <Mui.DialogContent>
            <Mui.DialogContentText id="alert-dialog-description">
              {dialogBody}
            </Mui.DialogContentText>
          </Mui.DialogContent>
          <Mui.DialogActions>
            <Mui.Button onClick={handleClose} color="primary" autoFocus>
              Ok
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Dialog>
      </>
    );
  }
);

export default OrderCouponCode;
