import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as App from "../../app";
import * as Component from "../../components";

interface MenuItemAppBarTitleProps {}

const MenuItemAppBarTitle: React.FC<MenuItemAppBarTitleProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );

    const { pathname } = Router.useLocation();
    const isMenuItemPage = pathname.includes("/item/");

    return (
      <>
        {isMenuItemPage && !userStore.isStaff && menuItemStore.menuItem && (
          <>
            <Component.ItemInfo {...menuItemStore.menuItem} />
          </>
        )}
      </>
    );
  }
);

export default MenuItemAppBarTitle;
