import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Styled from "../../styles";
import { withStyles } from "@material-ui/core/styles";

interface StaffMenuItemTagsCardProps {}

const StaffMenuItemTagsCard: React.FC<StaffMenuItemTagsCardProps> = Mobx.observer((props) => {
  const userStore = React.useContext(App.Context.User.User);
  const menuItemStore = React.useContext(App.Context.Restaurant.Menu.Item.Item);
  const menuItemEditStore = React.useContext(App.Context.Restaurant.Menu.Item.Edit);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!menuItemEditStore.isEditable || !menuItemEditStore.item) {
      return;
    }

    // menuItemEditStore.toggleTags();
    menuItemEditStore.saveTagInfo(event.target.value as string);
  };

  const getTagDetail = (): string => {
    if (menuItemEditStore.isEditable && menuItemEditStore.item) {
      if (menuItemEditStore.item.tags) {
        return menuItemEditStore.item.tags[0];
      }
    } else if (menuItemStore.menuItem && menuItemStore.menuItem.type) {
      if (menuItemStore.menuItem.tags) {
        return menuItemStore.menuItem.tags[0];
      }
    }
    return "";
  };
  const checkItemVeg = (): boolean => {
    if (menuItemEditStore.isEditable) {
      if (menuItemEditStore.item && !menuItemEditStore.item.tags) {
        menuItemEditStore.toggleTags();
        return false;
      }
      if (
        menuItemEditStore.item &&
        menuItemEditStore.item.tags &&
        menuItemEditStore.item.tags.length > 0 &&
        menuItemEditStore.item.tags[0] === "veg"
      ) {
        return true;
      }
    } else {
      if (
        menuItemStore.menuItem &&
        menuItemStore.menuItem.tags &&
        menuItemStore.menuItem.tags.length > 0 &&
        menuItemStore.menuItem.tags[0] === "veg"
      ) {
        return true;
      }
    }
    return false;
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: Mui.colors.red[500],
      "&$checked": {
        color: Mui.colors.green[500],
      },
      "&$checked + $track": {
        backgroundColor: Mui.colors.green[500],
      },
    },
    checked: {},
    track: { backgroundColor: Mui.colors.red[500] },
  })(Mui.Switch);

  return (
    <>
      {userStore.isStaff && ((menuItemEditStore.isEditable && menuItemEditStore.item) || menuItemStore.menuItem) && (
        <>
          <Mui.ExpansionPanel expanded={true}>
            <Styled.Mui.ExpansionPanelSummary>
              <Mui.Grid container justify="space-between" alignItems="center">
                <Mui.Grid container justify="flex-start" xs={2} sm={2}>
                  <Mui.Typography variant="h6">Tags</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid direction="row" justify="flex-end" container xs={10} sm={10}>
                  <Mui.FormControl>
                    <Mui.InputLabel id="demo-simple-select-label">Tags</Mui.InputLabel>
                    <Mui.Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={getTagDetail()}
                      onChange={handleChange}
                      fullWidth
                    >
                      <Mui.MenuItem value={""}>None</Mui.MenuItem>
                      <Mui.MenuItem value={"veg"}>Veg</Mui.MenuItem>
                      <Mui.MenuItem value={"non-veg"}>Non-veg</Mui.MenuItem>
                      <Mui.MenuItem value={"veg/non-veg"}>Veg / Non-Veg</Mui.MenuItem>
                      <Mui.MenuItem value={"egg"}>Egg</Mui.MenuItem>
                    </Mui.Select>
                  </Mui.FormControl>
                  {/*  <Mui.Grid item>
                    <Mui.Typography variant="subtitle1">Non-Veg</Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item>
                    <CustomSwitch checked={checkItemVeg()} onChange={handleChange()} /> 
                  </Mui.Grid>
                  <Mui.Grid item>
                    <Mui.Typography variant="subtitle1">Veg</Mui.Typography>
                  </Mui.Grid> */}
                </Mui.Grid>
              </Mui.Grid>
            </Styled.Mui.ExpansionPanelSummary>
          </Mui.ExpansionPanel>
        </>
      )}
    </>
  );
});

export default StaffMenuItemTagsCard;
