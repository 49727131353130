import * as Mui from "@material-ui/core";
import * as Firebase from "firebase/app";
import "firebase/auth";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "../../app";
import * as Component from "../../components";

interface OrderContinueCheckoutWithoutPaymentButtonProps {
  disabled: boolean;
}

const OrderContinueCheckoutWithoutPaymentButton: React.FC<OrderContinueCheckoutWithoutPaymentButtonProps> = Mobx.observer(
  (props) => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const toastStore = React.useContext(App.Context.Toast);

    const [user, setUser] = React.useState<Firebase.User | null>(null);

    Firebase.auth().onAuthStateChanged((user) => setUser(user));

    const history = Router.useHistory();

    function handleContinueCheckoutWithoutPaymentClick(user: Firebase.User) {
      return async function () {
        await orderStore.requestCreateOrderWithoutPayment(
          user.uid,
          user.phoneNumber as string,
          restaurantStore.room.roomNumber,
          toastStore
        );
        // .then(() => history.push("/user/account"));
        history.push("/user/account");
      };
    }

    return (
      <>
        {!userStore.isStaff &&
          user &&
          user.phoneNumber &&
          orderStore.order &&
          (!orderStore.order.status || orderStore.order.status === "created") &&
          orderStore.isOrderEditable && (
            <Mui.Button
              variant="contained"
              fullWidth
              color="primary"
              disabled={orderStore.isOrderCreating || props.disabled}
              onClick={handleContinueCheckoutWithoutPaymentClick(user)}
            >
              <Component.TwoLabel
                label1={
                  <>
                    {orderStore.isOrderCreating ? (
                      <Mui.CircularProgress />
                    ) : (
                      "Place Order"
                    )}
                  </>
                }
                label2={<Component.Price price={orderStore.total} />}
              ></Component.TwoLabel>
            </Mui.Button>
          )}
      </>
    );
  }
);

export default OrderContinueCheckoutWithoutPaymentButton;
