import * as Model from "../../../models";

export function images(
  images: Model.Restaurant.Images = {}
): Model.Restaurant.Image[] {
  const processedImages = Object.keys(images)
    .map(key => images[key])
    .filter(image => image.active);
  return processedImages;
}
