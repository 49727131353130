import * as Mui from "@material-ui/core";
import ContainerPaddingBottom from "./container-padding-bottom";

const ContainerLargePaddingBottom = Mui.styled(ContainerPaddingBottom)(
  ({ theme }) => ({
    paddingBottom: theme.spacing(19)
  })
);

export default ContainerLargePaddingBottom;
