import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as ReactFire from "reactfire";

import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

import * as App from "../../app";

const CustomSwitch = withStyles({
  switchBase: {
    color: Mui.colors.red[500],
    "&$checked": {
      color: Mui.colors.green[500],
    },
    "&$checked + $track": {
      backgroundColor: Mui.colors.green[500],
    },
  },
  checked: {},
  track: { backgroundColor: Mui.colors.red[500] },
})(Mui.Switch);

const EngagementLayerRestaurantDialog: React.FC<any> = Mobx.observer((props) => {
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const engagementStore = React.useContext(App.Context.Restaurant.Menu.EngagementLayer);

  const engagementLayerCollRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("pro-menu")
    .doc("engagement_layer")
    .collection("templates");

  const restaurantRef = ReactFire.useFirebaseApp().firestore().collection("restaurants");

  React.useEffect(() => {
    engagementStore.getTemplates(engagementLayerCollRef);
  }, []);

  const handleClose = () => {
    restaurantStore.closeEngagementLayerDialog();
  };

  const handleEngagementLayerSave = () => {
    // Need to save this information in to the database.

    if (!restaurantStore.restaurant) {
      return;
    }
    if (!restaurantStore.restaurant.restaurantId) {
      return;
    }
    restaurantStore.closeEngagementLayerDialog();
    let docId = restaurantStore.newEngagementTemplate;
    if (restaurantStore.newEngagementTemplate.trim() === "" && restaurantStore.restaurant.engagementLayerTemplate) {
      docId = restaurantStore.restaurant.engagementLayerTemplate;
    }
    console.log(restaurantStore.restaurant.restaurantId);
    restaurantRef
      .doc(restaurantStore.restaurant.restaurantId)
      .set(
        { engagementLayerTemplate: docId, showEngagementLayer: restaurantStore.restaurantEngagementActive },
        { merge: true }
      )
      .then(() => {
        console.log("DONE WITH THE UPDATE");
        restaurantStore.closeEngagementLayerDialog();
      })
      .catch(() => {
        console.log("ERROR IN THE UPDATE");
        restaurantStore.closeEngagementLayerDialog();
      });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    restaurantStore.changeEngagementTemplate(event.target.value as string);
  };

  const handleSwitchChange = () => {
    restaurantStore.toggleEngagementLayer();
  };

  return (
    <Mui.Container maxWidth="sm" fixed>
      <Mui.Dialog
        open={restaurantStore.openEngagementLayerDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Mui.DialogTitle id="form-dialog-title">Apply Engagement Layer</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText>
            Select required template and enable or disable the engagement layer
          </Mui.DialogContentText>
          Template:{" "}
          <Mui.Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            value={
              restaurantStore.restaurant
                ? restaurantStore.newEngagementTemplate !== ""
                  ? restaurantStore.newEngagementTemplate
                  : restaurantStore.restaurant.engagementLayerTemplate
                : ""
            }
            onChange={handleChange}
          >
            {engagementStore.templates.map((template) => (
              <Mui.MenuItem key={template.id} value={template.id}>
                {template.name}
              </Mui.MenuItem>
            ))}
          </Mui.Select>
          <br />
          Enable / Disable :{" "}
          <CustomSwitch checked={restaurantStore.restaurantEngagementActive} onChange={handleSwitchChange} />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleClose} color="primary">
            Cancel
          </Mui.Button>
          <Mui.Button onClick={handleEngagementLayerSave} color="primary">
            Save
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </Mui.Container>
  );
});

export default EngagementLayerRestaurantDialog;
