import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as Router from "react-router-dom";
import * as Icon from "@material-ui/icons";
import * as App from "../../app";
import * as Firebase from "firebase/app";
import "firebase/firestore";

interface StaffOrdersAppBarTitleProps {}

const HomeAppBarCityChangeButton: React.FC<StaffOrdersAppBarTitleProps> = Mobx.observer(
  props => {
    const mallContext = React.useContext(App.Context.Mall.Mall);

    const { pathname } = Router.useLocation();

    const isHomePage = pathname === "/";

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose(city?: string) {
      return function() {
        setAnchorEl(null);

        if (!city) {
          return;
        }

        mallContext.subscribe(Firebase.firestore().collection("malls"), city);
      };
    }

    return (
      <>
        {isHomePage && mallContext.cities.length > 0 && (
          <>
            <Mui.IconButton
              aria-haspopup="true"
              color="inherit"
              onClick={handleClick}
            >
              <Icon.MoreVert />
            </Mui.IconButton>
            <Mui.Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose()}
            >
              {mallContext.cities.map((city, index) => (
                <div key={`City${city}#${index}`}>
                  <Mui.MenuItem onClick={handleClose(city)}>
                    {city}
                  </Mui.MenuItem>
                </div>
              ))}
            </Mui.Menu>
          </>
        )}
      </>
    );
  }
);

export default HomeAppBarCityChangeButton;
