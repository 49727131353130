/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Styled from "../../styles";
import * as Mui from "@material-ui/core";
import * as Yup from "yup";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as App from "../../app";
import * as Model from "../../models";
import * as ReactFire from "reactfire";
import * as Helper from "../../helpers";
import * as Component from "../../components";
import "firebase/auth";

interface StaffLoginProps {}

const StaffLogin: React.FC<StaffLoginProps> = Mobx.observer(props => {
  const userStore = React.useContext(App.Context.User.User);

  const validationSchema = Yup.object<Model.Form.StaffLogin>({
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    password: Yup.string()
      .min(6)
      .required("Password is required")
  });

  const firebaseApp = ReactFire.useFirebaseApp();
  const usersRef = firebaseApp.firestore().collection("users");

  React.useEffect(() => {
    const unsubscribe: () => void = firebaseApp
      .auth()
      .onAuthStateChanged(async user => {
        if (!user) {
          return;
        }

        const isStaff = await Helper.User.isStaff(user);
        if (isStaff) {
          userStore.subscribe(usersRef.doc(user.uid));
        }
      }, console.error);

    return () => {
      unsubscribe();
    };
  }, []);

  async function handleSubmit(
    { email, password }: Model.Form.StaffLogin,
    {
      setSubmitting,
      setFieldError
    }: Formik.FormikHelpers<Model.Form.StaffLogin>
  ) {
    try {
      const credential = await firebaseApp
        .auth()
        .signInWithEmailAndPassword(email, password);

      const isStaff = await Helper.User.isStaff(credential.user);
      const isAdmin = await Helper.User.isAdmin(credential.user);

      if (!isStaff && credential.user) {
        firebaseApp.auth().signOut();
        setFieldError("password", "Invalid email or password");
      }
    } catch (error) {
      const errorCode:
        | "auth/invalid-email"
        | "auth/user-disabled"
        | "auth/user-not-found"
        | "auth/wrong-password" = error.code;

      switch (errorCode) {
        case "auth/invalid-email": {
          setFieldError("email", error.message);
          break;
        }
        case "auth/wrong-password": {
          setFieldError("password", error.message);
          break;
        }
        case "auth/user-not-found": {
          setFieldError("email", error.message);
          break;
        }
        default: {
          console.error(error);
        }
      }
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
    {userStore.isAdmin && (
        <Router.Redirect
          to={`/uploadRestaurantData`}
        />
      )}
      {userStore.isStaff && !userStore.isAdmin && userStore.user && userStore.user.restaurantId && (
        <Router.Redirect
          to={`/restaurant/${userStore.user.restaurantId}/menu`}
        />
      )}
      <Styled.Page.Container>
        <Styled.Mui.TypographyBold variant="h5" gutterBottom>
          Staff Login
        </Styled.Mui.TypographyBold>
        <Formik.Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <Formik.Form>
              <Mui.Grid container spacing={1}>
                <Mui.Grid item xs={12}>
                  <Formik.Field
                    type="text"
                    name="email"
                    component={FormikMui.TextField}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Mui.Grid>
                <Mui.Grid item xs={12}>
                  <Formik.Field
                    type="password"
                    name="password"
                    component={FormikMui.TextField}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Mui.Grid>
                <Mui.Grid item xs={12}>
                  <Mui.Button
                    type="submit"
                    variant="contained"
                    disabled={!isValid || isSubmitting}
                    fullWidth
                    color="primary"
                  >
                    {isSubmitting ? <Mui.CircularProgress /> : "Login"}
                  </Mui.Button>
                </Mui.Grid>
              </Mui.Grid>
            </Formik.Form>
          )}
        </Formik.Formik>
      </Styled.Page.Container>
    </>
  );
});

export default Component.withTracker(StaffLogin);
