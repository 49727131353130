import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as App from "../../app";
import * as Styled from "../../styles";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import * as ReactFire from "reactfire";
import "firebase/storage";
import * as Config from "../../config";

interface StaffMenuItemImageProps {}

const StaffMenuItemImage: React.FC<StaffMenuItemImageProps> = Mobx.observer((props) => {
  const userStore = React.useContext(App.Context.User.User);
  const menuItemStore = React.useContext(App.Context.Restaurant.Menu.Item.Item);
  const menuItemEditStore = React.useContext(App.Context.Restaurant.Menu.Item.Edit);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

  const [image, setImage] = React.useState({} as File);
  const [progress, setProgress] = React.useState(0);
  const [upload, setUpload] = React.useState(false);
  const firebaseStorage = ReactFire.useFirebaseApp().storage();
  const appName = Config.Environment.appName || "dev";
  console.log("APP_NAME", appName);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        margin: "auto",
        maxWidth: 500,
      },
      image: {
        width: 128,
        height: 128,
      },
      img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%",
      },
      input: {
        display: "none",
      },
    })
  );
  const classes = useStyles();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!(event.target && event.target.files)) {
      return;
    }
    console.log(event.target.files[0]);
    setImage(event.target.files[0]);
  };

  const handleImageUpload = () => {
    if (
      !(
        image &&
        image.name &&
        restaurantStore.restaurant &&
        restaurantStore.restaurant.restaurantId &&
        menuItemEditStore.item &&
        menuItemEditStore.item.menuItemId
      )
    ) {
      return;
    }
    setUpload(true);
    const uploadTask = firebaseStorage
      .ref(`${appName}/${restaurantStore.restaurant.restaurantId}/${menuItemEditStore.item.menuItemId}`)
      .put(image);
    uploadTask.on("state_changed", (snapshot) => {
      setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    });
  };

  return (
    <>
      {userStore.isStaff &&
        menuItemEditStore.item &&
        restaurantStore &&
        restaurantStore.restaurant &&
        restaurantStore.restaurant.taxRate &&
        ((menuItemEditStore.isEditable && menuItemEditStore.item) || menuItemStore.menuItem) && (
          <>
            <Mui.Card>
              <Mui.CardContent>
                <Mui.Grid container spacing={2} alignItems="center">
                  <Mui.Grid item xs={12} sm container alignItems="center">
                    <Mui.Grid item xs container direction="column" spacing={2}>
                      <Mui.Grid item>
                        <Mui.ButtonBase className={classes.image}>
                          <img
                            className={classes.img}
                            alt="complex"
                            src={`https://res.cloudinary.com/ordaap/image/upload/fl_lossy,f_auto,q_auto,w_128,h_128,c_scale,c_fill/v1/${appName}/${restaurantStore.restaurant.restaurantId}/${menuItemEditStore.item.menuItemId}`}
                          />
                        </Mui.ButtonBase>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid>
                      <input accept="image/*" id="contained-button-file" type="file" onChange={handleImageChange} />
                    </Mui.Grid>
                    <Mui.Grid item justify="center" alignItems="center">
                      <Mui.Box>
                        <Mui.Button variant="contained" color="primary" component="span" onClick={handleImageUpload}>
                          Upload
                        </Mui.Button>
                      </Mui.Box>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.CardContent>
            </Mui.Card>
            <Mui.Dialog
              disableBackdropClick
              disableEscapeKeyDown
              open={upload}
              onClose={() => {
                setUpload(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Mui.DialogTitle id="alert-dialog-title">{"Uploading Images"}</Mui.DialogTitle>
              <Mui.DialogContent>
                <Mui.Box position="center" display="inline-flex">
                  <Mui.CircularProgress variant="static" value={progress} />
                  <Mui.Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Mui.Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >{`${progress}%`}</Mui.Typography>
                  </Mui.Box>
                </Mui.Box>
                {progress === 100 && (
                  <Mui.DialogContentText id="alert-dialog-description">
                    You uploaded image will take up to 5 mins to reflect on the system
                  </Mui.DialogContentText>
                )}
              </Mui.DialogContent>
              {progress === 100 && (
                <Mui.DialogActions>
                  <Mui.Button
                    onClick={() => {
                      setUpload(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Done
                  </Mui.Button>
                </Mui.DialogActions>
              )}
            </Mui.Dialog>
          </>
        )}
    </>
  );
});

export default StaffMenuItemImage;
