import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Styled from "../../styles";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";

interface StaffMenuItemTaxRateProps {}

const CustomSwitch = withStyles({
  switchBase: {
    color: Mui.colors.red[500],
    "&$checked": {
      color: Mui.colors.green[500],
    },
    "&$checked + $track": {
      backgroundColor: Mui.colors.green[500],
    },
  },
  checked: {},
  track: { backgroundColor: Mui.colors.red[500] },
})(Mui.Switch);

const StaffMenuItemTaxRate: React.FC<StaffMenuItemTaxRateProps> = Mobx.observer((props) => {
  const userStore = React.useContext(App.Context.User.User);
  const menuItemStore = React.useContext(App.Context.Restaurant.Menu.Item.Item);
  const menuItemEditStore = React.useContext(App.Context.Restaurant.Menu.Item.Edit);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      select: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    })
  );
  const classes = useStyles();

  function enableItemTaxChange() {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      if (!menuItemEditStore.isEditable || !menuItemEditStore.item) {
        return;
      }

      menuItemEditStore.toggleItemTaxSlab();
    };
  }

  const handleRateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!menuItemEditStore.isEditable || !menuItemEditStore.item) {
      return;
    }
    const rate: string = event.target.value as string;
    menuItemEditStore.modifyItemTaxRate(parseInt(rate), `GST ${event.target.value}%`);
  };

  return (
    <>
      {userStore.isStaff &&
        menuItemEditStore.item &&
        restaurantStore &&
        restaurantStore.restaurant &&
        restaurantStore.restaurant.taxRate &&
        ((menuItemEditStore.isEditable && menuItemEditStore.item) || menuItemStore.menuItem) && (
          <>
            <Mui.ExpansionPanel expanded={menuItemEditStore.item.gstSlab}>
              <Styled.Mui.ExpansionPanelSummary>
                <Mui.Grid container justify="space-between" alignItems="center">
                  <Mui.Grid>
                    <Mui.Typography variant="h6">Indivigual Item Tax </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid>
                    <CustomSwitch checked={menuItemEditStore.item.gstSlab} onChange={enableItemTaxChange()} />
                  </Mui.Grid>
                </Mui.Grid>
                {/* <CustomSwitch
                checked={
                  menuItemEditStore.isEditable && menuItemEditStore.item
                    ? menuItemEditStore.item.available
                    : (menuItemStore.menuItem && menuItemStore.menuItem.available) || false
                }
                onChange={handleChange()}
              /> */}
              </Styled.Mui.ExpansionPanelSummary>
              <Mui.ExpansionPanelDetails>
                <Mui.Grid container justify="space-between" alignItems="center">
                  <Mui.Grid>
                    <Mui.FormControl className={classes.select}>
                      <Mui.InputLabel id="demo-simple-select-label">Tax Rate</Mui.InputLabel>
                      <Mui.Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={menuItemEditStore.item.gstSlabRate || 0}
                        onChange={handleRateChange}
                      >
                        <Mui.MenuItem value={0}>GST 0%</Mui.MenuItem>
                        <Mui.MenuItem value={5}>GST 5%</Mui.MenuItem>
                        <Mui.MenuItem value={12}>GST 12%</Mui.MenuItem>
                        <Mui.MenuItem value={18}>GST 18%</Mui.MenuItem>
                        <Mui.MenuItem value={28}>GST 28%</Mui.MenuItem>
                      </Mui.Select>
                    </Mui.FormControl>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.ExpansionPanelDetails>
            </Mui.ExpansionPanel>
          </>
        )}
    </>
  );
});

export default StaffMenuItemTaxRate;
