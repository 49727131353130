import * as Model from "../../../models";

export function categories(
  categories: Model.Restaurant.Categories = {}
): Model.Restaurant.Category[] {
  const processedCategories = Object.keys(categories)
    .map(key => ({ ...categories[key], categoryId: key.trim() }))
    .sort((a, b) =>
      a.displayOrder < b.displayOrder ? a.displayOrder : b.displayOrder
    );
  return processedCategories;
}
