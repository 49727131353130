import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as ReactFire from "reactfire";

import CloseIcon from "@material-ui/icons/Close";

import * as App from "../../app";
import { Image, Transformation } from "cloudinary-react";

const useStyles = makeStyles((theme) => ({
  appBar: {},
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  imageTag: {
    maxWidth: "100%",
    maxHeight: "100vh",
    margin: "auto",
  },
  videoTag: {
    maxWidth: "100%",
    minHeight: "270px",
    margin: "0",
  },
  imageDiv: {
    display: "grid",
    height: "100%",
  },
  dialog: {
    padding: 0,
  },
  iframe: {
    minHeight: "270px",
    width: "100%",
  },
  fabButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: "#ffffff",
    backgroundColor: "#000000",
  },
}));

const EngagementLayerView: React.FC<any> = Mobx.observer((props) => {
  const classes = useStyles();
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const engagementLayerCollRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("pro-menu")
    .doc("engagement_layer")
    .collection("templates");

  React.useEffect(() => {
    restaurantStore.getTemplate(engagementLayerCollRef);
  }, []);

  const handleClose = () => {
    restaurantStore.closeEngagementLayer();
    setOpen(false);
  };

  return (
    <Mui.Container maxWidth="sm" fixed>
      <Mui.Dialog
        fullScreen
        open={restaurantStore.showEngagementLayer && !restaurantStore.engagementLayerAccessed}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <Mui.DialogContent className={classes.dialog}>
          <Mui.Grid container alignItems="center">
            <Mui.Grid item xs={12} className={classes.imageDiv}>
              <div id="videoContainer" className={classes.videoTag}>
                <iframe
                  id="ytplayer"
                  src={`${restaurantStore.engagementTemplate.video}?autoplay=1&loop=1`}
                  frameBorder="0"
                  title="YouPlayer"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  className={classes.iframe}
                ></iframe>
              </div>
            </Mui.Grid>
            <Mui.Grid item xs={12} className={classes.imageDiv}>
              <Image
                width="inherit"
                cloudName="ordaap"
                publicId={restaurantStore.engagementTemplate.image}
                type="fetch"
                className={classes.imageTag}
              >
                <Transformation width="auto" quality="auto" crop="fit" fetchFormat="auto" />
              </Image>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Fab variant="extended" onClick={handleClose} className={classes.fabButton}>
            Access Menu
          </Mui.Fab>
        </Mui.DialogContent>
      </Mui.Dialog>
    </Mui.Container>
  );
});

export default EngagementLayerView;
