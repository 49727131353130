import * as React from "react";
import * as Router from "react-router-dom";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";

interface StaffMenuItemEditCancelButtonProps {}

const StaffMenuItemEditCancelButton: React.FC<StaffMenuItemEditCancelButtonProps> = Mobx.observer((props) => {
  const userStore = React.useContext(App.Context.User.User);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const menuItemEditStore = React.useContext(App.Context.Restaurant.Menu.Item.Edit);

  const location = Router.useLocation();
  const history = Router.useHistory();

  function handleClick(restaurantId: string, menuItemId: string) {
    return async function () {
      menuItemEditStore.reset();
      if (location.pathname.includes("/add")) {
        history.push(`/restaurant/${restaurantId}/menu`);
      }

      if (location.pathname.includes("/edit")) {
        history.push(`/restaurant/${restaurantId}/item/${menuItemId}`);
      }
    };
  }

  return (
    <>
      {userStore.isStaff &&
        restaurantStore.restaurant &&
        restaurantStore.restaurant.restaurantId &&
        menuItemEditStore.isEditable &&
        menuItemEditStore.item &&
        menuItemEditStore.item.menuItemId && (
          <Mui.Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={handleClick(restaurantStore.restaurant.restaurantId, menuItemEditStore.item.menuItemId)}
          >
            Cancel
          </Mui.Button>
        )}
    </>
  );
});

export default StaffMenuItemEditCancelButton;
