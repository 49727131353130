import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";

interface InfoProps {
  title: string;
  body: React.ReactNode;
}

const Info: React.FC<InfoProps> = props => {
  return (
    <>
      <Styled.Mui.TypographyBold variant="h5">
        {props.title}
      </Styled.Mui.TypographyBold>
      <Mui.Typography variant="subtitle2" color="textSecondary">
        {props.body}
      </Mui.Typography>
    </>
  );
};

export default Info;
