import * as Model from "../../../../models";

export function menu(
  menu: Model.Restaurant.Menu.Item.Item[]
): Map<string, Model.Restaurant.Menu.Item.Item[]> {
  const menuMap = new Map();

  menu.map(menuItem => {
    menuMap.set(menuItem.categoryId, [
      ...(menuMap.get(menuItem.categoryId) || []),
      menuItem
    ]);
    return undefined;
  });

  return menuMap;
}
