import * as Mui from "@material-ui/core";

const MenuContainer = Mui.styled("div")(({ theme }) => ({
  marginTop: -theme.spacing(3),
  borderRadius: theme.spacing(3, 3, 0, 0),
  padding: theme.spacing(3, 2, 3, 2),
  backgroundColor: theme.palette.background.default
}));

export default MenuContainer;
