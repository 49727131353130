import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";

interface OrderPaymentInfoProps {}

const OrderPaymentInfo: React.FC<OrderPaymentInfoProps> = Mobx.observer(
  props => {
    const orderStore = React.useContext(App.Context.Restaurant.Order);

    return (
      <>
        {orderStore.order && orderStore.order.paymentStatus && (
          <Styled.Mui.ListItemNoPadding>
            <Mui.ListItemText
              primary={
                <>
                  <Mui.Grid
                    container
                    justify="space-between"
                    alignItems="center"
                  >
                    <Mui.Typography variant="body2">Payment</Mui.Typography>
                    <Component.PaymentBadge
                      status={orderStore.order.paymentStatus}
                    />
                  </Mui.Grid>
                </>
              }
            />
          </Styled.Mui.ListItemNoPadding>
        )}
      </>
    );
  }
);

export default OrderPaymentInfo;
