import * as React from "react";
import * as Styled from "../../styles";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as Model from "../../models";
import * as Util from "../../utils";

export enum ITEM_INFO_VARIANT {
  LIST = "LIST",
  HERO = "HERO",
}

interface ItemInfoProps {
  available?: boolean;
  name: string;
  price: number;
  total?: number;
  quantity?: number;
  description?: string;
  tags?: string[];
  variant?: ITEM_INFO_VARIANT;
  hide?: boolean;
  operatingHours?: Model.Restaurant.OperatingHours;
}

const ItemInfo: React.FC<ItemInfoProps> = (props) => {
  const getTagIcon = (tags: string[]) => {
    if (!tags) {
      return;
    }
    switch (tags[0]) {
      case "veg":
        return (
          <svg height="15" width="15">
            <rect width="15" height="15" stroke="green" strokeWidth="3" fill="white" />
            <circle cx="7.5" cy="7.5" r="4" stroke="green" strokeWidth="0" fill="green" />
          </svg>
        );

      case "non-veg":
        return (
          <svg height="15" width="15">
            <rect width="15" height="15" stroke="red" strokeWidth="3" fill="white" />
            <circle cx="7.5" cy="7.5" r="4" stroke="red" strokeWidth="0" fill="red" />
          </svg>
        );

      case "veg/non-veg":
        return (
          <>
            <svg height="15" width="15">
              <rect width="15" height="15" stroke="green" strokeWidth="3" fill="white" />
              <circle cx="7.5" cy="7.5" r="4" stroke="green" strokeWidth="0" fill="green" />
            </svg>
            &nbsp;
            <svg height="15" width="15">
              <rect width="15" height="15" stroke="red" strokeWidth="3" fill="white" />
              <circle cx="7.5" cy="7.5" r="4" stroke="red" strokeWidth="0" fill="red" />
            </svg>
          </>
        );

      case "veg/non veg":
        return (
          <>
            <svg height="15" width="15">
              <rect width="15" height="15" stroke="green" strokeWidth="3" fill="white" />
              <circle cx="7.5" cy="7.5" r="4" stroke="green" strokeWidth="0" fill="green" />
            </svg>
            &nbsp;
            <svg height="15" width="15">
              <rect width="15" height="15" stroke="red" strokeWidth="3" fill="white" />
              <circle cx="7.5" cy="7.5" r="4" stroke="red" strokeWidth="0" fill="red" />
            </svg>
          </>
        );

      case "egg":
        return (
          <svg height="15" width="15">
            <rect width="15" height="15" stroke="orange" strokeWidth="3" fill="white" />
            <circle cx="7.5" cy="7.5" r="4" stroke="orange" strokeWidth="0" fill="orange" />
          </svg>
        );
      default:
        return <></>;
    }
  };
  const isItemAvailable = () => {
    if (props.available === undefined) {
      return true;
    }
    return props.available ? Util.Restaurant.isItemUnderOperatingHours(props.operatingHours) : false;
  };
  return (
    <>
      <Mui.ListItemText
        disableTypography
        primary={
          <>
            <Mui.Grid container justify="space-between">
              {props.variant === ITEM_INFO_VARIANT.HERO ? (
                <Styled.Mui.TypographyBold variant="h5">
                  {props.tags && getTagIcon(props.tags)}
                  &nbsp;
                  {props.name}
                </Styled.Mui.TypographyBold>
              ) : (
                <Mui.Typography>
                  {props.tags && getTagIcon(props.tags)}
                  &nbsp;
                  {props.name}{" "}
                  {props.quantity && (
                    <Mui.Typography component="span" variant="body2" color="textSecondary">
                      &nbsp;(x{props.quantity})
                    </Mui.Typography>
                  )}
                </Mui.Typography>
              )}
              <div>
                <Styled.Mui.TypographyBold
                  component="span"
                  variant={props.variant === ITEM_INFO_VARIANT.HERO ? "h5" : undefined}
                >
                  <Component.Price price={props.total || props.price} />
                </Styled.Mui.TypographyBold>
              </div>
            </Mui.Grid>
          </>
        }
        secondary={
          props.description || props.tags || !isItemAvailable() ? (
            <>
              <Mui.Grid container justify="space-between">
                <Mui.Typography color="textSecondary" variant="body2" gutterBottom>
                  {props.description}
                </Mui.Typography>
                {!isItemAvailable() && (
                  <Styled.Mui.TypographyBold color="textSecondary" variant="body2" gutterBottom>
                    {`Not Available`}
                  </Styled.Mui.TypographyBold>
                )}
                {props.hide && (
                  <Styled.Mui.TypographyBold color="textSecondary" variant="body2" gutterBottom>
                    {`Item Hided`}
                  </Styled.Mui.TypographyBold>
                )}
              </Mui.Grid>
            </>
          ) : undefined
        }
      />
    </>
  );
};

export default ItemInfo;
