import * as Model from "../../../models";
import * as Mobx from "mobx";
import * as Firebase from "firebase/app";
import * as Util from "../../../utils";
import "firebase/firestore";

export class Menu {
  @Mobx.observable public expandedCategoryId?: string | false;

  // Added for Enabling Food Types
  @Mobx.observable public foodTypes: Set<string> = new Set();
  @Mobx.observable public currentFoodType : 'food' | 'bar' = "food";


  @Mobx.observable public items: Map<
    string,
    Model.Restaurant.Menu.Item.Item[]
  > = new Map();

  @Mobx.observable public allItems: Map<
  string,
  Model.Restaurant.Menu.Item.Item[]
> = new Map();
  @Mobx.observable public isFetching: boolean = true;
  @Mobx.observable public restaurantId: string = "";

  constructor() {
    this.setExpandedCategoryId = this.setExpandedCategoryId.bind(this);
  }

  @Mobx.action public setExpandedCategoryId(
    expandedCategoryId: string | false
  ) {
    this.expandedCategoryId = expandedCategoryId;
  }

  @Mobx.action public subscribe(
    menuRef: Firebase.firestore.CollectionReference,
    restaurantRef: Firebase.firestore.DocumentReference
  ) {
    if (this.items.size && this.restaurantId === restaurantRef.id) {
      return;
    }

    // this.rehydrate(restaurantId);

    // const menuRef: Firebase.firestore.CollectionReference = Firebase.firestore()
    //   .collection("resturants")
    //   .doc(restaurantId)
    //   .collection("menu");
    this.restaurantId = restaurantRef.id;
    this.isFetching = true;
    const unsubscribe: () => void = menuRef.onSnapshot(
      snapshot => {
        const menu: Model.Restaurant.Menu.Item.Item[] = snapshot.docs.map(
          doc =>
            ({
              ...doc.data(),
              menuItemId: doc.id
            } as Model.Restaurant.Menu.Item.Item)
        );
        const menuItems = Util.Curate.Restaurant.Menu.menu(menu);
      // Adding the Food Types from Here
      this.foodTypes.clear();
      menu.forEach((menuItem) => {
        if(menuItem.type && menuItem.type.trim() !== '') {
          this.foodTypes.add(menuItem.type.toLowerCase());
        }
      })

       
      // End Adding the food types
        this.allItems = menuItems;
        this.applyMenuType('food');
        this.isFetching = false;
        // this.persist(restaurantId, menu);
      },
      (error: Error) => {
        console.error(error);
        unsubscribe();
      },
      () => unsubscribe()
    );
  }

  @Mobx.action public applyMenuType(type: 'food' | 'bar' ) {

    if(this.foodTypes.size <= 1) {
      this.items = this.allItems;
      return;
    }
    this.currentFoodType = type; 
    const itemsWithType: Map<
    string,
    Model.Restaurant.Menu.Item.Item[]
  > = new Map();
    let availableTags:string[] = [];
    if(type === 'food') {
      availableTags = ['food']
    }
    this.allItems.forEach((catgItems:Model.Restaurant.Menu.Item.Item[] , catId: string) => {
      if(catgItems) {
        const tempTypeItems = catgItems.filter((item) => {
          if(!item.type) {
            return true;
          }
          const itemType = item.type.toLowerCase();
          if(this.currentFoodType === 'food' && (itemType === 'food' || itemType === '')) {
            return true;
          } else if(this.currentFoodType === 'bar' && (itemType === 'drinks' || itemType=== 'liquor' || itemType === 'drink' || itemType === 'bar')) {
            return true;
          }
          return false;
        })
        if(tempTypeItems && tempTypeItems.length > 0 )
        {
          itemsWithType.set(catId, tempTypeItems);
        }
      }
      this.items = itemsWithType;
      
    })


    // Here is the Logic to add the Items for different Menu type
    
  }

  // @Mobx.action private rehydrate(restaurantId: string) {
  //   const serializedMenu = localStorage.getItem(`${restaurantId}-menu`);
  //   if (!serializedMenu) {
  //     localStorage.clear();
  //     return;
  //   }

  //   const deserializedMenu = JSON.parse(serializedMenu);
  //   const menuMap = Util.Curate.Restaurant.Menu.menu(deserializedMenu);
  //   this.menuMap = menuMap;
  // }

  // private persist(restaurantId: string, menu: Model.Restaurant.Menu.Item.Item[]) {
  //   localStorage.setItem(`${restaurantId}-menu`, JSON.stringify(menu));
  // }
}
