import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";

interface OrderTotalInfoProps {}

const OrderTotalInfo: React.FC<OrderTotalInfoProps> = Mobx.observer(props => {
  const orderStore = React.useContext(App.Context.Restaurant.Order);

  return (
    <>
      {orderStore.order &&
        orderStore.order.status !== undefined &&
        orderStore.order.status !== "created" && (
          <Styled.Mui.ListItemNoPadding>
            <Mui.ListItemText
              primary={
                <>
                  <Mui.Grid
                    container
                    justify="space-between"
                    alignItems="center"
                  >
                    <Mui.Typography variant="body2">Total</Mui.Typography>
                    <Styled.Mui.TypographyBold variant="body2">
                      <Component.Price price={orderStore.total} />
                    </Styled.Mui.TypographyBold>
                  </Mui.Grid>
                </>
              }
            />
          </Styled.Mui.ListItemNoPadding>
        )}
    </>
  );
});

export default OrderTotalInfo;
