import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Model from "../../models";

interface AccountAppBarTitleProps {}

const AccountAppBarTitle: React.FC<AccountAppBarTitleProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);

    const theme = Mui.useTheme();
    const { pathname } = Router.useLocation();
    const isAccountPage = pathname.includes("/user/account");

    return (
      <>
        {isAccountPage &&
          !userStore.isStaff &&
          userStore.user &&
          (userStore.user as Model.User).phoneNumber && (
            <>
              <Mui.Typography variant="h6">
                {(userStore.user as Model.User).phoneNumber}
              </Mui.Typography>
              {userStore.user.email && (
                <Mui.Typography
                  variant="body2"
                  style={{ marginTop: theme.spacing(-1) }}
                >
                  {userStore.user.email}
                </Mui.Typography>
              )}
            </>
          )}
        {isAccountPage && !userStore.isStaff && !userStore.user && (
          <>
            <Mui.Typography variant="h6">My Account</Mui.Typography>
          </>
        )}
      </>
    );
  }
);

export default AccountAppBarTitle;
