import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Model from "../../models";

interface StaffMenuItemAddRequiredCustomizationCardProps {}

const StaffMenuItemAddRequiredCustomizationCard: React.FunctionComponent<StaffMenuItemAddRequiredCustomizationCardProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const menuItemEditStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Edit
    );

    function handleClick() {
      menuItemEditStore.addNewEmptyRequiredCustomization();
      const customizationArrayIndex: number =
        menuItemEditStore.item!.requiredCustomization!.length - 1;
      const customization: Model.Restaurant.Menu.Item.CuratedCustomization = menuItemEditStore.item!
        .requiredCustomization![customizationArrayIndex];
      menuItemEditStore.addNewEmptyCustomizationOption(
        customization,
        customizationArrayIndex
      );
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          menuItemEditStore.item &&
          menuItemEditStore.isEditable && (
            <Mui.ExpansionPanel expanded={true}>
              <Mui.Button color="primary" fullWidth onClick={handleClick}>
                Add new required customization
              </Mui.Button>
            </Mui.ExpansionPanel>
          )}
      </>
    );
  }
);

export default StaffMenuItemAddRequiredCustomizationCard;
