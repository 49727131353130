// import * as Model from "../../../models";
import * as Mobx from "mobx";
import "firebase/firestore";

export class Edit {
  @Mobx.observable public isEditable?: boolean;

  // @Mobx.observable public items: Map<
  //   string,
  //   Model.Restaurant.Menu.Item.Item[]
  // > = new Map();

  @Mobx.action public reset() {
    this.setIsEditable();
  }

  @Mobx.action public setIsEditable(isEditable?: boolean) {
    this.isEditable = isEditable;
  }
}
