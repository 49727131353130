import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "../../app";
import * as Container from "../../containers";
import * as Model from "../../models";

interface StaffOrderCloseButtonProps {}

const StaffOrderCloseButton: React.FC<StaffOrderCloseButtonProps> = Mobx.observer(
  (props) => {
    const [
      acknowledgementDialogOpen,
      setAcknowledgementDialogOpen,
    ] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const staffOrderStore = React.useContext(App.Context.Staff.Order);

    const history = Router.useHistory();

    function handleClick(order: Model.Restaurant.Order.Order) {
      return async function () {
        setAcknowledgementDialogOpen(true);
        setIsLoading(true);
        await staffOrderStore.closeOrder(order);
        setIsLoading(false);
        setTimeout(() => handleAcknowledgementDialogClose(), 2000);
      };
    }

    function handleAcknowledgementDialogClose() {
      setAcknowledgementDialogOpen(false);
      history.goBack();
    }

    return (
      <>
        <Container.StaffOrderClosedAcknowledgementDialog
          open={acknowledgementDialogOpen}
          isLoading={isLoading}
          handleClose={handleAcknowledgementDialogClose}
        />
        {userStore.isStaff &&
          orderStore.order &&
          orderStore.order.status === "ready" &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId && (
            <Mui.Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleClick(orderStore.order)}
            >
              Order Served
            </Mui.Button>
          )}
      </>
    );
  }
);

export default StaffOrderCloseButton;
