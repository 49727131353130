/* eslint-disable react-hooks/exhaustive-deps */
import * as Mui from "@material-ui/core";
import * as Firebase from "firebase/app";
import * as React from "react";
import * as ReactFire from "reactfire";
import * as ErrorBoundry from "../errors";
import * as Config from "../config";
import * as Route from "../routes";
import * as Store from "../stores";
// import * as ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import "./app.css";

Sentry.init({
  dsn: `https://${Config.Sentry.key}@sentry.io/${Config.Sentry.project}`,
});

// ReactGA.initialize(Config.GoogleAnalytics.trackingId, {
//   debug: Config.Environment.production ? false : true,
//   testMode: Config.Environment.production ? false : true
// });

const firebaseApp = Firebase.initializeApp(Config.Firebase);

firebaseApp.analytics();

export const Context = {
  Restaurant: {
    Category: {
      Edit: React.createContext(new Store.Restaurant.Category.Edit()),
    },
    Restaurant: React.createContext(new Store.Restaurant.Restaurant()),
    Menu: {
      Item: {
        Customization: {
          Edit: React.createContext(new Store.Restaurant.Menu.Item.Customization.Edit()),
        },
        Item: React.createContext(new Store.Restaurant.Menu.Item.Item()),
        Edit: React.createContext(new Store.Restaurant.Menu.Item.Edit()),
      },
      Menu: React.createContext(new Store.Restaurant.Menu.Menu()),
      Edit: React.createContext(new Store.Restaurant.Menu.Edit()),
      EngagementLayer: React.createContext(new Store.Restaurant.Menu.EngagementLayer()),
    },
    Order: React.createContext(new Store.Restaurant.Order()),
  },
  Staff: {
    Order: React.createContext(new Store.Staff.Order()),
    OrderHistory: React.createContext(new Store.Staff.OrderHistory()),
  },
  Mall: {
    Mall: React.createContext(new Store.Mall.Mall()),
    Restaurant: React.createContext(new Store.Mall.Restaurant()),
  },
  Toast: React.createContext(new Store.Toast()),
  User: {
    User: React.createContext(new Store.User.User()),
    Account: React.createContext(new Store.User.Account()),
  },
};

const App: React.FC = () => {
  const [, setErrorBoundry] = React.useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);

  React.useEffect(() => {
    const unsubscribe: () => void = firebaseApp.auth().onAuthStateChanged(
      function (user) {
        if (!user) {
          firebaseApp
            .auth()
            .signInAnonymously()
            .catch(() => {
              unsubscribe();
              setErrorBoundry(() => {
                throw new Error("Authentication failed");
              });
            });
          return;
        }

        setIsAuthenticated(true);
        unsubscribe();
      },
      () => {
        unsubscribe();
        setErrorBoundry(() => {
          throw new Error("Authentication failed");
        });
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <Mui.CssBaseline />
      <ErrorBoundry.AppFailed>
        <ReactFire.FirebaseAppProvider firebaseConfig={Config.Firebase}>
          {isAuthenticated && <Route.Global />}
        </ReactFire.FirebaseAppProvider>
      </ErrorBoundry.AppFailed>
    </>
  );
};

export default App;
