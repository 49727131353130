import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as App from "../../app";
import * as Styled from "../../styles";
import * as Model from "../../models";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";

interface StaffMenuItemOperatingHoursProps {}

const StaffMenuItemOperatingHours: React.FC<StaffMenuItemOperatingHoursProps> = Mobx.observer((props) => {
  const userStore = React.useContext(App.Context.User.User);
  const menuItemStore = React.useContext(App.Context.Restaurant.Menu.Item.Item);
  const menuItemEditStore = React.useContext(App.Context.Restaurant.Menu.Item.Edit);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      select: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    })
  );
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [startTime, setStartTime] = React.useState(`00:00:00`);
  const [endTime, setEndTime] = React.useState(`23:59:00`);

  const handleClickOpen = () => {
    menuItemEditStore.editOperatingHours();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [weekday, setWeekDay] = React.useState(`monday`);
  const handleWeekdayChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!event.target.value) {
      return;
    }
    setWeekDay(event.target.value as string);
  };

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(`${event.target.value}:00`);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(`${event.target.value}:00`);
  };

  const handleOperatingHoursSave = () => {
    menuItemEditStore.acceptChanges();
    handleClose();
  };

  const handleOperatingHoursCancel = () => {
    menuItemEditStore.cancelChanges();
    handleClose();
  };

  const handleRemoveDay = (day: string) => {
    const weekday = day as Model.Restaurant.Days;
    menuItemEditStore.deleteOperatingHourForDay(weekday);
  };
  const handleTimeAdd = () => {
    // console.log(weekday, startTime, endTime);
    const startTimeParts = startTime.split(":");
    const endTimeParts = endTime.split(":");
    if (startTimeParts[0] > endTimeParts[0]) {
      // end time is less than start time
      return;
    } else if (startTimeParts[0] === endTimeParts[0] && startTimeParts[1] >= endTimeParts[1]) {
      // End time is less than start time
      return;
    }
    menuItemEditStore.addOperatingHoursForDay(weekday as Model.Restaurant.Days, { startTime, endTime });
  };

  function editItemOperatingHours() {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      if (!menuItemEditStore.isEditable || !menuItemEditStore.item) {
        return;
      }

      menuItemEditStore.toggleItemTaxSlab();
    };
  }

  return (
    <>
      {userStore.isStaff &&
        menuItemEditStore.item &&
        restaurantStore &&
        restaurantStore.restaurant &&
        restaurantStore.restaurant.taxRate &&
        ((menuItemEditStore.isEditable && menuItemEditStore.item) || menuItemStore.menuItem) && (
          <>
            <Mui.ExpansionPanel expanded={menuItemEditStore.item.gstSlab}>
              <Styled.Mui.ExpansionPanelSummary>
                <Mui.Grid container justify="space-between" alignItems="center">
                  <Mui.Grid>
                    <Mui.Typography variant="h6">Menu Operating Times </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid>
                    <MuiIcons.Edit onClick={handleClickOpen} />
                  </Mui.Grid>
                </Mui.Grid>
              </Styled.Mui.ExpansionPanelSummary>
            </Mui.ExpansionPanel>
            <Mui.Dialog open={open} onClose={handleClose}>
              <Styled.Menu.MenuContainer>
                <Mui.DialogTitle id="customized-dialog-title">Modify Operating Hours</Mui.DialogTitle>
                <form noValidate>
                  <Mui.FormControl>
                    <Mui.InputLabel id="demo-simple-select-label">Age</Mui.InputLabel>
                    <Mui.Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={weekday}
                      fullWidth
                      onChange={handleWeekdayChange}
                    >
                      <Mui.MenuItem value={`monday`}>Monday</Mui.MenuItem>
                      <Mui.MenuItem value={`tuesday`}>Tuesday</Mui.MenuItem>
                      <Mui.MenuItem value={`wednesday`}>Wednesday</Mui.MenuItem>
                      <Mui.MenuItem value={`thursday`}>Thursday</Mui.MenuItem>
                      <Mui.MenuItem value={`friday`}>Friday</Mui.MenuItem>
                      <Mui.MenuItem value={`saturday`}>Saturday</Mui.MenuItem>
                      <Mui.MenuItem value={`sunday`}>Sunday</Mui.MenuItem>
                    </Mui.Select>
                  </Mui.FormControl>
                  <Mui.TextField
                    id="time"
                    label="Start time"
                    type="time"
                    defaultValue="00:00"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 1800, // 30 min
                    }}
                    onChange={handleStartTimeChange}
                  />
                  <Mui.TextField
                    id="time"
                    label="End time"
                    type="time"
                    fullWidth
                    defaultValue="23:59"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 1800, // 30 min
                    }}
                    onChange={handleEndTimeChange}
                  />
                  <br />
                  <Mui.Button autoFocus variant="contained" color="primary" onClick={handleTimeAdd}>
                    Add
                  </Mui.Button>
                </form>
                <br />
                <br />
                {menuItemEditStore && menuItemEditStore.item && menuItemEditStore.operatingHoursEdit && (
                  <Mui.Grid container>
                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Mon</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {menuItemEditStore.operatingHoursEdit.monday.length > 0 ? (
                              menuItemEditStore.operatingHoursEdit.monday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                AVAILABLE ON OPERATING HOURS
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`monday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Tue</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {menuItemEditStore.operatingHoursEdit.tuesday.length > 0 ? (
                              menuItemEditStore.operatingHoursEdit.tuesday.map((timings) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                AVAILABLE ON OPERATING HOURS
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`tuesday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Wed</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {menuItemEditStore.operatingHoursEdit.wednesday.length > 0 ? (
                              menuItemEditStore.operatingHoursEdit.wednesday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                AVAILABLE ON OPERATING HOURS
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`wednesday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Thu</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {menuItemEditStore.operatingHoursEdit.thursday.length > 0 ? (
                              menuItemEditStore.operatingHoursEdit.thursday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                AVAILABLE ON OPERATING HOURS
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`thursday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Fri</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {menuItemEditStore.operatingHoursEdit.friday.length > 0 ? (
                              menuItemEditStore.operatingHoursEdit.friday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                AVAILABLE ON OPERATING HOURS
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`friday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Sat</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {menuItemEditStore.operatingHoursEdit.saturday.length > 0 ? (
                              menuItemEditStore.operatingHoursEdit.saturday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                AVAILABLE ON OPERATING HOURS
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`saturday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Sun</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {menuItemEditStore.operatingHoursEdit.sunday.length > 0 ? (
                              menuItemEditStore.operatingHoursEdit.sunday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                AVAILABLE ON OPERATING HOURS
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`sunday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>
                  </Mui.Grid>
                )}
              </Styled.Menu.MenuContainer>
              <Mui.DialogActions>
                <Mui.Button autoFocus onClick={handleOperatingHoursCancel} color="primary">
                  Cancel changes
                </Mui.Button>
                <Mui.Button autoFocus onClick={handleOperatingHoursSave} color="primary">
                  Accept changes
                </Mui.Button>
              </Mui.DialogActions>
            </Mui.Dialog>
          </>
        )}
    </>
  );
});

export default StaffMenuItemOperatingHours;
