import * as React from "react";
import * as Mui from "@material-ui/core";

interface InfoDialogProps {
  open: boolean;
  title?(): JSX.Element;
  body?(): JSX.Element;
  action?(): JSX.Element;
  handleClose(): void;
}

const InfoDialog: React.FC<InfoDialogProps> = props => {
  return (
    <>
      <Mui.Dialog open={props.open} onClose={props.handleClose}>
        <Mui.DialogTitle>{props.title && props.title()}</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText>
            {props.body && props.body()}
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>{props.action && props.action()}</Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default InfoDialog;
