import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";

interface MenuItemDeleteFromOrderIconButtonProps {}

const MenuItemDeleteFromOrderIconButton: React.FC<MenuItemDeleteFromOrderIconButtonProps> = Mobx.observer(
  props => {
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const toastStore = React.useContext(App.Context.Toast);

    const history = Router.useHistory();

    function handleDelete(restaurantId: string) {
      return function() {
        orderStore.removeItem();
        history.push(`/order`);
        toastStore.showToast({
          body: "Item deleted from Order"
        });
      };
    }

    return (
      <>
        {menuItemStore.menuItem &&
          orderStore.editItem &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId && (
            <Mui.Button
              variant="contained"
              color="secondary"
              onClick={handleDelete(restaurantStore.restaurant.restaurantId)}
            >
              <Icon.Delete />
            </Mui.Button>
          )}
      </>
    );
  }
);

export default MenuItemDeleteFromOrderIconButton;
