import * as Mui from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
// import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Component from "../../components";
import * as Model from "../../models";

interface StaffOrdersAppBarTitleProps {}

const OrderAppBarTag: React.FC<StaffOrdersAppBarTitleProps> = Mobx.observer(
  (props) => {
    const orderStore = React.useContext(App.Context.Restaurant.Order);

    const { pathname } = Router.useLocation();
    const isOrderPage =
      !pathname.includes("/orders") && pathname.includes("/order");

    return (
      <>
        {isOrderPage && orderStore.order && orderStore.order.orderId && (
          <>
            {orderStore!.order!.roomNumber && (
              <Mui.Button
                variant="outlined"
                color="inherit"
                startIcon={<Icons.MeetingRoom />}
                style={{ marginRight: 16 }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
              >
                <Mui.Box fontWeight="bold">
                  {orderStore!.order!.roomNumber}
                </Mui.Box>
              </Mui.Button>
            )}
            <Component.OrderBadge
              status={
                orderStore.order.cancelled
                  ? "cancelled"
                  : (orderStore.order.status as Model.Restaurant.Order.Status)
              }
            />
          </>
        )}
      </>
    );
  }
);

export default OrderAppBarTag;
