/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as ReactFire from "reactfire";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Icon from "@material-ui/icons";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Model from "../../models";
import * as App from "../../app";
import * as Component from "../../components";
import * as Util from "../../utils";

interface RestaurantsProps {}

const Restaurants: React.FC<RestaurantsProps> = Mobx.observer(props => {
  const theme = Mui.useTheme();
  const params = Router.useParams<Model.RouteParams.Restaurants>();

  const mallRestaurantStore = React.useContext(App.Context.Mall.Restaurant);
  const userStore = React.useContext(App.Context.User.User);

  const restaurantsRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("restaurants");

  React.useEffect(() => {
    mallRestaurantStore.subscribe(restaurantsRef, params.mallId);
  }, []);

  return (
    <>
      {userStore.isStaff && <Router.Redirect to="/" />}
      {mallRestaurantStore.isFetching ? (
        <Component.PageLoadingIndicator />
      ) : (
        <>
          <Styled.Page.Container>
            {/* <Mui.Button
              variant="outlined"
              size="small"
              color="primary"
              startIcon={<Icon.ArrowBackIos />}
              component={Router.Link}
              to="/"
            >
              View Malls
            </Mui.Button>
            <Styled.Page.Title>
              <Styled.Mui.TypographyBold variant="h5">
                V R Mall
              </Styled.Mui.TypographyBold>
            </Styled.Page.Title> */}
            <Mui.Grid container spacing={2}>
              {mallRestaurantStore.restaurants.map(restaurant => (
                <Mui.Grid
                  key={`Restaurant#${restaurant.restaurantId}`}
                  item
                  xs={12}
                >
                  <Mui.Card>
                    <Mui.CardActionArea
                      style={
                        restaurant.isLive
                          ? { display: "flex", justifyContent: "flex-start" }
                          : {
                              display: "flex",
                              justifyContent: "flex-start",
                              opacity: 0.5
                            }
                      }
                      component={Router.Link}
                      to={`/restaurant/${restaurant.restaurantId}/menu`}
                      disabled={!restaurant.isLive}
                    >
                      <Mui.CardMedia
                        style={{ width: 100, height: 100 }}
                        image={restaurant.thumbnail}
                      />
                      <Mui.CardContent style={{ flexGrow: 1 }}>
                        {!Util.Restaurant.isOpen(restaurant.operatingHours) && (
                          <Component.OrderBadge status="closed" />
                        )}
                        <Mui.Typography component="h6" variant="h6">
                          <Mui.Grid container justify="space-between">
                            <Mui.Grid item>{restaurant.name}</Mui.Grid>
                            <Mui.Grid item>
                              {!restaurant.isLive && (
                                <Mui.Chip
                                  style={{
                                    backgroundColor: "green",
                                    color: "white"
                                  }}
                                  label="Coming Soon!!!"
                                />
                              )}
                            </Mui.Grid>
                          </Mui.Grid>
                        </Mui.Typography>
                        <Mui.Typography
                          variant="body2"
                          color="textSecondary"
                          gutterBottom
                          style={{ marginTop: theme.spacing(-0.5) }}
                        >
                          {restaurant.cuisines}
                        </Mui.Typography>
                        <Styled.Mui.Divider style={{ width: "100%" }} />
                        <div style={{ display: "flex" }}>
                          {restaurant.isOutlet ? (
                            <Mui.Grid container alignItems="center">
                              <span
                                style={{
                                  color: Mui.colors.deepOrange[500],
                                  marginRight: theme.spacing(0.5)
                                }}
                              >
                                <Icon.Fastfood
                                  fontSize="small"
                                  color="inherit"
                                />
                              </span>
                              <Mui.Typography
                                variant="body2"
                                color="textSecondary"
                              >
                                Outlet
                              </Mui.Typography>
                            </Mui.Grid>
                          ) : (
                            <Mui.Grid container alignItems="center">
                              <span
                                style={{
                                  color: Mui.colors.deepPurple[500],
                                  marginRight: theme.spacing(0.5)
                                }}
                              >
                                <Icon.LocalDining
                                  fontSize="small"
                                  color="inherit"
                                />
                              </span>
                              <Mui.Typography
                                variant="body2"
                                color="textSecondary"
                              >
                                Restaurant
                              </Mui.Typography>
                            </Mui.Grid>
                          )}
                          {restaurant.isPureVeg && (
                            <Mui.Grid container alignItems="center">
                              <span
                                style={{
                                  color: Mui.colors.deepPurple[500],
                                  marginRight: theme.spacing(0.5)
                                }}
                              >
                                <Icon.Eco fontSize="small" htmlColor="green" />
                              </span>
                              <Mui.Typography
                                variant="body2"
                                color="textSecondary"
                              >
                                Pure Veg
                              </Mui.Typography>
                            </Mui.Grid>
                          )}
                        </div>
                      </Mui.CardContent>
                    </Mui.CardActionArea>
                  </Mui.Card>
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </Styled.Page.Container>
        </>
      )}
    </>
  );
});

export default Component.withTracker(Restaurants);
