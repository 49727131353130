import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as App from "../../app";

interface MenuCategoryInfoProps {
  name: string;
  items: number;
}

const MenuCategoryInfo: React.FC<MenuCategoryInfoProps> = Mobx.observer(
  props => {
    const categoryEditStore = React.useContext(
      App.Context.Restaurant.Category.Edit
    );
    return (
      <>
        {!categoryEditStore.isEditable && (
          <Mui.Grid container justify="space-between" alignItems="center">
            <Mui.Typography>{props.name}</Mui.Typography>
            <Mui.Typography color="textSecondary">{props.items}</Mui.Typography>
          </Mui.Grid>
        )}
      </>
    );
  }
);

export default MenuCategoryInfo;
