import * as React from "react";
import * as Mui from "@material-ui/core";

interface TwoLabelProps {
  label1: React.ReactNode;
  label2: React.ReactNode;
}

const TwoLabel: React.FC<TwoLabelProps> = props => {
  return (
    <>
      <Mui.Grid container justify="space-between" alignItems="center">
        <Mui.Typography component="div">{props.label1}</Mui.Typography>
        <Mui.Typography>{props.label2}</Mui.Typography>
      </Mui.Grid>
    </>
  );
};

export default TwoLabel;
