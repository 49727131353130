/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as ReactFire from "reactfire";
import * as Router from "react-router-dom";
import * as Styled from "../../styles";
import * as Model from "../../models";
import * as App from "../../app";
import * as Component from "../../components";
import * as Container from "../../containers";

interface OrdersHistoryProps {}

const OrdersHistory: React.FC<OrdersHistoryProps> = Mobx.observer(props => {
  const { restaurantId } = Router.useParams<Model.RouteParams.OrdersHistory>();

  const userStore = React.useContext(App.Context.User.User);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const menuStore = React.useContext(App.Context.Restaurant.Menu.Menu);
  const staffOrdersHistoryStore = React.useContext(
    App.Context.Staff.OrderHistory
  );

  const theme = Mui.useTheme();

  const restaurantRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("restaurants")
    .doc(restaurantId);

  const ordersRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("orders");

  const menuRef = restaurantRef.collection("menu");

  React.useEffect(() => {
    restaurantStore.subscribe(restaurantRef);
    menuStore.subscribe(menuRef, restaurantRef);
    staffOrdersHistoryStore.subscribe(ordersRef, restaurantId);
  }, []);

  function showClosedOrders(showClosed: boolean) {
    return function() {
      staffOrdersHistoryStore.setClosedOrders(showClosed);
    };
  }

  function loadMoreClosedOrders() {
    return function() {
      staffOrdersHistoryStore.loadMoreClosedOrders();
    };
  }

  function loadMoreCancelledOrders() {
    return function() {
      staffOrdersHistoryStore.loadMoreCancelledOrders();
    };
  }

  return (
    <>
      {!userStore.isStaff && <Router.Redirect to="/" />}
      {!staffOrdersHistoryStore.fetchedData ? (
        <Component.PageLoadingIndicator />
      ) : (
        <>
          <Styled.Page.Container>
            {/* <Styled.Page.Title>
              <Styled.Mui.TypographyBold variant="h5">
                Orders History
              </Styled.Mui.TypographyBold>
            </Styled.Page.Title> */}
            <Mui.Grid container justify="flex-start" alignItems="center">
              <Mui.Grid item xs={10}>
                <div
                  style={{
                    overflow: "auto",
                    whiteSpace: "nowrap"
                  }}
                >
                  <Mui.Button
                    style={{ marginRight: theme.spacing(1) }}
                    size="small"
                    variant={
                      staffOrdersHistoryStore.showClosedOrders
                        ? "contained"
                        : "outlined"
                    }
                    color={
                      staffOrdersHistoryStore.showClosedOrders
                        ? "primary"
                        : "default"
                    }
                    onClick={showClosedOrders(true)}
                  >
                    Closed
                  </Mui.Button>
                  <Mui.Button
                    style={{ marginRight: theme.spacing(1) }}
                    variant={
                      !staffOrdersHistoryStore.showClosedOrders
                        ? "contained"
                        : "outlined"
                    }
                    size="small"
                    color={
                      !staffOrdersHistoryStore.showClosedOrders
                        ? "primary"
                        : "default"
                    }
                    onClick={showClosedOrders(false)}
                  >
                    Cancelled
                  </Mui.Button>
                </div>
              </Mui.Grid>
            </Mui.Grid>
            {staffOrdersHistoryStore.showClosedOrders
              ? staffOrdersHistoryStore.closedOrders!.map((order, index) => (
                  <React.Fragment key={`Order#${order.orderId}-${index}`}>
                    <Container.OrderInfoCard order={order} />
                  </React.Fragment>
                ))
              : staffOrdersHistoryStore.cancelledOrders!.map((order, index) => (
                  <React.Fragment key={`Order#${order.orderId}-${index}`}>
                    <Container.OrderInfoCard order={order} />
                  </React.Fragment>
                ))}{" "}
            {staffOrdersHistoryStore.showClosedOrders &&
              staffOrdersHistoryStore.fetchMoreClosedOrder && (
                <Mui.Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={loadMoreClosedOrders()}
                >
                  Load More
                </Mui.Button>
              )}
            {!staffOrdersHistoryStore.showClosedOrders &&
              staffOrdersHistoryStore.fetchMoreCancelledOrder && (
                <Mui.Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={loadMoreCancelledOrders()}
                >
                  Load More
                </Mui.Button>
              )}{" "}
          </Styled.Page.Container>
        </>
      )}
    </>
  );
});

export default Component.withTracker(OrdersHistory);
