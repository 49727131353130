import * as Model from "../../../../../../models";

export function options(
  option: Model.Restaurant.Menu.Item.Customization.Options
): Model.Restaurant.Menu.Item.Customization.Option[] {
  const options = Object.keys(option).map(key => ({
    ...option[key],
    optionId: key
  }));

  return options;
}
