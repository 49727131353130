import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as Styled from "../../styles";
import * as App from "../../app";

interface MenuItemEditProps {}

const MenuItemEdit: React.FC<MenuItemEditProps> = Mobx.observer(props => {
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const editMenuItemStore = React.useContext(
    App.Context.Restaurant.Menu.Item.Edit
  );

  const theme = Mui.useTheme();

  return (
    <>
      {editMenuItemStore.item && (
        <Mui.ListItemText
          disableTypography
          primary={
            <>
              <Mui.Grid
                container
                style={{ margin: theme.spacing(1, 0, 0, 0) }}
                justify="space-between"
                spacing={0}
              >
                <Mui.Grid item xs={9}>
                  <Styled.Mui.TypographyBold
                    style={{ marginRight: theme.spacing(1) }}
                    component="div"
                    variant="h5"
                  >
                    <Mui.TextField
                      margin="dense"
                      fullWidth
                      label="Name"
                      variant="outlined"
                      value={editMenuItemStore.item.name}
                    />
                  </Styled.Mui.TypographyBold>
                </Mui.Grid>

                <Mui.Grid item xs={3}>
                  <Styled.Mui.TypographyBold component="div" variant="h5">
                    <Mui.TextField
                      margin="dense"
                      fullWidth
                      label={`Price ${
                        restaurantStore.restaurant &&
                        restaurantStore.restaurant.currency
                          ? restaurantStore.restaurant.currency.symbol
                          : "₹"
                      }`}
                      variant="outlined"
                      value={editMenuItemStore.item.price}
                    />
                  </Styled.Mui.TypographyBold>
                </Mui.Grid>
              </Mui.Grid>
            </>
          }
          secondary={
            <>
              <Mui.Grid container spacing={0}>
                <Mui.Grid item xs={12}>
                  <Mui.Typography
                    color="textSecondary"
                    component="div"
                    variant="body2"
                  >
                    <Mui.TextField
                      margin="dense"
                      fullWidth
                      multiline
                      label="Description"
                      variant="outlined"
                      value={editMenuItemStore.item.description}
                    />
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid item xs={12}>
                  <Mui.Typography
                    color="textSecondary"
                    component="div"
                    variant="body2"
                  >
                    <Mui.TextField
                      margin="dense"
                      fullWidth
                      multiline
                      label="Extra Description"
                      variant="outlined"
                      value={editMenuItemStore.item.extraDescription}
                    />
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            </>
          }
        />
      )}
    </>
  );
});

export default MenuItemEdit;
