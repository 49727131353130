import * as React from "react";
import * as Mui from "@material-ui/core";
import * as FormikMui from "formik-material-ui";

const AutoSubmitting = (props: FormikMui.TextFieldProps) => (
  <Mui.TextField
    {...FormikMui.fieldToTextField(props)}
    onBlur={event => {
      props.form.submitForm();
    }}
  />
);

export default AutoSubmitting;
