import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as Model from "../../models";
import * as App from "../../app";

interface StaffMenuItemCustomizationInfoProps {
  customization: Model.Restaurant.Menu.Item.CuratedCustomization;
}

const StaffMenuItemCustomizationInfo: React.FC<StaffMenuItemCustomizationInfoProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const menuItemEditStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Edit
    );

    return (
      <>
        {userStore.isStaff && !menuItemEditStore.isEditable && (
          <Mui.Grid container justify="space-between" alignItems="baseline">
            <Mui.Typography variant="h6">
              {props.customization.requiredCustomizationId && (
                <Mui.Typography component="span" color="error" variant="h6">
                  *&nbsp;
                </Mui.Typography>
              )}
              {props.customization.name}
            </Mui.Typography>
            <Mui.Typography variant="body2" color="textSecondary">
              {props.customization.min || props.customization.max}{" "}
              selection(s)&nbsp;
              {props.customization.requiredCustomizationId
                ? "required"
                : "allowed"}
            </Mui.Typography>
          </Mui.Grid>
        )}
      </>
    );
  }
);

export default StaffMenuItemCustomizationInfo;
