import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as Firebase from "firebase/app";
import "firebase/auth";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "../../app";
import * as Model from "../../models";

interface AccountAppBarMenuButtonProps {}

const AccountAppBarMenuButton: React.FC<AccountAppBarMenuButtonProps> = Mobx.observer(
  (props) => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const { pathname } = Router.useLocation();
    const isAccountPage = pathname.includes("/user/account");

    function handleLogoutClick() {
      Firebase.auth().signOut();
      window.location.reload();
    }

    return (
      <>
        {isAccountPage &&
          userStore.user &&
          (userStore.user as Model.User).phoneNumber && (
            <Mui.Button
              variant="outlined"
              color="inherit"
              startIcon={<Icon.Store />}
              component={Router.Link}
              to={
                restaurantStore.restaurant &&
                restaurantStore.restaurant.restaurantId
                  ? `/restaurant/${restaurantStore.restaurant.restaurantId}/menu`
                  : "#"
              }
            >
              Menu
            </Mui.Button>
          )}
      </>
    );
  }
);

export default AccountAppBarMenuButton;
