import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as Model from "../../models";
import * as App from "../../app";
import * as Helper from "../../helpers";

interface MenuItemCustomizationInfoProps {
  customization: Model.Restaurant.Menu.Item.CuratedCustomization;
}

const MenuItemCustomizationInfo: React.FC<MenuItemCustomizationInfoProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );

    const checkedOptions = Helper.Restaurant.Menu.Item.Customization.getCheckedOptions(
      props.customization,
      menuItemStore.customizations
    );

    const checkedOptionCount = (checkedOptions && checkedOptions.size) || 0;

    return (
      <>
        {!userStore.isStaff && (
          <Mui.Grid container justify="space-between" alignItems="baseline">
            <Mui.Typography variant="h6">
              {props.customization.requiredCustomizationId && (
                <Mui.Typography component="span" color="error" variant="h6">
                  *&nbsp;
                </Mui.Typography>
              )}
              {props.customization.name}
            </Mui.Typography>
            <Mui.Typography variant="body2" color="textSecondary">
              {checkedOptionCount} /{" "}
              {props.customization.min || props.customization.max} selected
            </Mui.Typography>
          </Mui.Grid>
        )}
      </>
    );
  }
);

export default MenuItemCustomizationInfo;
