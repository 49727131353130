import * as Firebase from "firebase/app";
import "firebase/firestore";
import * as Mobx from "mobx";
import * as Model from "../../models";
import * as Util from "../../utils";

interface EngagementLayerTemplate {
  id?: string;
  name: string;
  video: string;
  image: string;
}

export class Restaurant {
  @Mobx.observable public restaurant?: Model.Restaurant.Restaurant;
  @Mobx.observable public isFetching: boolean = true;

  // The following observables is for showing the engagement layer
  @Mobx.observable public openEngagementLayerDialog: boolean = false;
  @Mobx.observable public showEngagementLayer: boolean = false;
  @Mobx.observable public engagementLayerAccessed: boolean = false;
  @Mobx.observable public engagementTemplate: EngagementLayerTemplate = {id:'', name:'', video:'',image:''};
  // The following is added for updating restauratn with the Engagement Layer
  @Mobx.observable public newEngagementTemplate: string = '';
  @Mobx.observable public restaurantEngagementActive: boolean = false;
  

  
  @Mobx.observable public room: { roomNumber: string; timestamp: number } = {
    roomNumber: "",
    timestamp: 0,
  };
  @Mobx.observable public operatingHoursEdit?: Model.Restaurant.OperatingHours;

  @Mobx.action public setRoom(room: { roomNumber: string; timestamp: number }) {
    this.room = room;
  }

  @Mobx.action public closeEngagementLayer() {
    this.showEngagementLayer = false;
    this.engagementLayerAccessed = true;
  }

  @Mobx.action public showEngagementLayerDialog() {
    this.openEngagementLayerDialog = true;
  }

  @Mobx.action public closeEngagementLayerDialog() {
    this.openEngagementLayerDialog = false;
  }

  @Mobx.action public toggleEngagementLayer() {
    this.restaurantEngagementActive = !this.restaurantEngagementActive;
  }

  @Mobx.action public changeEngagementTemplate(templateId: string) {
    this.newEngagementTemplate = templateId;
  }

  @Mobx.action public async checkRestaurantExists(restaurantRef: Firebase.firestore.CollectionReference, restaurantId: string) {
   
    return restaurantRef.doc(restaurantId).get().then((documentSnapshot)=>{
      if(documentSnapshot.exists) {
        return true;
      } else {
        return false;
      }
    })
  }

  @Mobx.action public async createRestaurantData(restaurantRef: Firebase.firestore.CollectionReference, restaurantData: any, menuData: any) {
    const docRef = restaurantRef.doc(restaurantData.restaurantId);
    const menuRef = restaurantRef.doc(restaurantData.restaurantId).collection("menu");
      let promises = [];
      const restanrantPromise = docRef.set(restaurantData);
      promises.push(restanrantPromise);
     menuData.map(async (data: any,index: number) => {
        const promise = menuRef.doc(data.id).set(data);
        console.log(index, data.id);
        promises.push(promise);
        });
        return await Promise.all(promises).then((values) => {
       return true;
      });
  }

  @Mobx.action public getTemplate(templateRef: Firebase.firestore.CollectionReference) {
    console.log("INSIDE USE EFFECT");
    if(this.restaurant && this.restaurant.showEngagementLayer && this.restaurant.engagementLayerTemplate && this.restaurant.engagementLayerTemplate!=='' && this.engagementTemplate.id ==='') {
      console.log("FETCHING THE TEMPLATE INFORMATION");
      templateRef.doc(this.restaurant.engagementLayerTemplate).get().then((docSnapShot) => {
        this.engagementTemplate = { id: docSnapShot.id, ...docSnapShot.data() } as EngagementLayerTemplate
      })
    }
  }

  @Mobx.action public subscribe(restaurantRef: Firebase.firestore.DocumentReference) {
    this.rehydrateRoom(restaurantRef.id);
    if (this.restaurant && this.restaurant.restaurantId && this.restaurant.restaurantId === restaurantRef.id) {
      return;
    }

    // this.rehydrate(restaurantId);

    // const restaurantRef: Firebase.firestore.DocumentReference = this.db
    //   .collection("resturants")
    //   .doc(restaurantId);

    const unsubscribe: () => void = restaurantRef.onSnapshot(
      (snapshot) => {
        const restaurant: Model.Restaurant.Restaurant = {
          ...snapshot.data(),
          restaurantId: snapshot.id,
        } as Model.Restaurant.Restaurant;
        this.restaurant = restaurant;
        this.isFetching = false;
        console.log("ENGAGEMENT LAYER",restaurant.showEngagementLayer);
        this.showEngagementLayer = restaurant.showEngagementLayer || false;
        this.restaurantEngagementActive = restaurant.showEngagementLayer || false;
        // this.persist(restaurantId, restaurant);
      },
      (error: Error) => {
        console.error(error);
        unsubscribe();
      },
      () => unsubscribe()
    );
  }

  

  @Mobx.action public editOperatingHours() {
    if (!this.restaurant) {
      return;
    }
    this.operatingHoursEdit = { ...this.restaurant.operatingHours };
  }
  @Mobx.action public addOperatingHoursForDay(weekday: Model.Restaurant.Days, operatingTime: Model.Restaurant.Time) {
    if (!this.operatingHoursEdit) {
      return;
    }
    this.operatingHoursEdit[weekday].push(operatingTime);
  }
  @Mobx.action public deleteOperatingHourForDay(weekday: Model.Restaurant.Days) {
    if (!this.operatingHoursEdit) {
      return;
    }
    this.operatingHoursEdit[weekday] = [];
  }

  @Mobx.action private rehydrateRoom(restaurantId: string) {
    if (this.room.roomNumber && this.room.timestamp) {
      return;
    }

    const json = JSON.parse(localStorage.getItem(`room@${restaurantId}`) || "{}");

    const room: {
      roomNumber: string;
      timestamp: number;
    } = Object.keys(json).length
      ? json
      : {
          roomNumber: "",
          timestamp: 0,
        };

    console.log("rehydrating room from localstorage to", room);
    this.room = room;
  }

  @Mobx.computed public get images(): Model.Restaurant.Image[] | undefined {
    if (!this.restaurant) {
      return undefined;
    }

    const images = Util.Curate.Restaurant.images(this.restaurant.images);
    return images;
  }

  @Mobx.computed public get categories(): Model.Restaurant.Category[] | undefined {
    if (!this.restaurant) {
      return undefined;
    }

    const categories = Util.Curate.Restaurant.categories(this.restaurant.categories);
    return categories;
  }

  // @Mobx.action private rehydrate(restaurantId: string) {
  //   const serializedRestaurant = localStorage.getItem(
  //     `${restaurantId}-restaurant`
  //   );
  //   if (!serializedRestaurant) {
  //     localStorage.clear();
  //     return;
  //   }

  //   const deserializedRestaurant = JSON.parse(serializedRestaurant);
  //   this.restaurant = deserializedRestaurant;
  // }

  // private persist(
  //   restaurantId: string,
  //   restaurant: Model.Restaurant.Restaurant
  // ) {
  //   localStorage.setItem(
  //     `${restaurantId}-restaurant`,
  //     JSON.stringify(restaurant)
  //   );
  // }
}
