/* eslint-disable react-hooks/exhaustive-deps */
import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "../../app";

export enum BOTTOM_NAVIGATION {
  RESTAURANTS_PAGE,
  ORDER_PAGE,
  ACCOUNT_PAGE,
}

const StyledBottomNavigation = Mui.withStyles((theme) => ({
  root: { backgroundColor: Mui.colors.grey[300] },
}))(Mui.BottomNavigation);

interface BottomNavigationProps {}

const BottomNavigation: React.FC<BottomNavigationProps> = Mobx.observer(
  (props) => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

    const [value, setValue] = React.useState(
      BOTTOM_NAVIGATION.RESTAURANTS_PAGE
    );

    const { pathname } = Router.useLocation();

    function getBottomNavigationValue(pathname: string): number | undefined {
      if (pathname === "/" || pathname.includes("/mall/")) {
        return BOTTOM_NAVIGATION.RESTAURANTS_PAGE;
      }
      if (pathname.includes("/order")) {
        return BOTTOM_NAVIGATION.ORDER_PAGE;
      }
      if (pathname.includes("/account")) {
        return BOTTOM_NAVIGATION.ACCOUNT_PAGE;
      }
    }

    React.useEffect(() => {
      setValue(getBottomNavigationValue(pathname) as number);
    }, []);

    const isMenuPage = pathname.includes("/menu");

    return (
      <>
        {!userStore.isStaff &&
          !isMenuPage &&
          {
            // IRD-MASTER
            /*<StyledBottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            showLabels
          >
            <Mui.BottomNavigationAction
              label="Ordaap"
              icon={<Icon.Store />}
              component={Router.Link}
              to={
                restaurantStore.restaurant && restaurantStore.restaurant.mallId
                  ? `/mall/${restaurantStore.restaurant.mallId}`
                  : "/"
              }
            />
            <Mui.BottomNavigationAction
              label="Order"
              icon={<Icon.Receipt />}
              component={Router.Link}
              to="/order"
            />
            <Mui.BottomNavigationAction
              label="Account"
              icon={<Icon.Person />}
              component={Router.Link}
              to="/user/account"
            />
            </StyledBottomNavigation>*/
          }}
      </>
    );
  }
);

export default BottomNavigation;
