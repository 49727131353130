/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

interface IUseCheckoutParams {
  callback: () => void;
}

const useCheckout = ({ callback }: IUseCheckoutParams) => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = callback;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useCheckout;
