import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Model from "../../models";
import * as Component from "../../components";

import QRCode from "qrcode.react";

interface StaffMenuEditButtonProps {}

const StaffMenuEditButton: React.FC<StaffMenuEditButtonProps> = Mobx.observer((props) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userStore = React.useContext(App.Context.User.User);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const categoryEditStore = React.useContext(App.Context.Restaurant.Category.Edit);
  const menuEditStore = React.useContext(App.Context.Restaurant.Menu.Edit);

  const handleEngagementLayerOpen = () => {
    restaurantStore.showEngagementLayerDialog();
  };
  const downloadQR = () => {
    const canvas = document.getElementById("123456") as HTMLCanvasElement;
    if (!canvas) {
      return;
    }
    var ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    ctx.font = "3px Arial";
    ctx.fillText(restaurantStore.restaurant!.restaurantId || "", 20, 48);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = restaurantStore.restaurant!.restaurantId + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  function handleClick(categories: Model.Restaurant.Category[]) {
    return function () {
      menuEditStore.setIsEditable(true);
      categoryEditStore.setIsEditable(true);
      categoryEditStore.setOriginalCategories(categories);
    };
  }

  return (
    <>
      {userStore.isStaff &&
        restaurantStore.restaurant &&
        restaurantStore.categories &&
        (!categoryEditStore.isEditable || !menuEditStore.isEditable) && (
          <>
            <br />
            <Mui.Button onClick={handleClick(restaurantStore.categories)} color="primary" variant="contained" fullWidth>
              Edit Categories
            </Mui.Button>
            <br />
            <br />
            <Mui.Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Mui.DialogTitle id="alert-dialog-title">{"Restaurant QR"}</Mui.DialogTitle>
              <Mui.DialogContent>
                <Mui.DialogContentText id="alert-dialog-description">
                  <div>
                    <QRCode id="123456" value={window.location.href} size={290} level={"H"} includeMargin={true} />
                    <a href="#" onClick={downloadQR}>
                      {" "}
                      Download QR{" "}
                    </a>
                  </div>
                </Mui.DialogContentText>
              </Mui.DialogContent>
            </Mui.Dialog>
            <Mui.Button onClick={handleClickOpen} color="primary" variant="contained" fullWidth>
              Download QR
            </Mui.Button>
            <br />
            <br />
            <Mui.Button onClick={handleEngagementLayerOpen} color="primary" variant="contained" fullWidth>
              Engagement Layer
            </Mui.Button>
            {restaurantStore.showEngagementLayerDialog && <Component.EngagementLayerRestaurantDialog />}
          </>
        )}
    </>
  );
});

export default StaffMenuEditButton;
