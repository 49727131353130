import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Mobx from "mobx-react-lite";
import * as App from "../../app";
import * as Container from "../../containers";
import * as Component from "../../components";

interface OrderOverviewCardProps {}

const OrderOverviewCard: React.FC<OrderOverviewCardProps> = Mobx.observer(
  props => {
    const orderStore = React.useContext(App.Context.Restaurant.Order);

    return (
      <>
        {orderStore.order && orderStore.order.items.length > 0 && (
          <>
            <Mui.ExpansionPanel expanded={true}>
              <Styled.Mui.ExpansionPanelSummaryDisabled>
                <Mui.Grid container direction="column">
                  {/* <Container.RestaurantInfo /> */}
                  <Component.Info
                    title={orderStore.order.restaurantName}
                    body={
                      <>
                        {/* <Icon.WhereToVote />
                        &nbsp; */}
                        {orderStore.order.restaurantAddress}
                      </>
                    }
                  />
                </Mui.Grid>
              </Styled.Mui.ExpansionPanelSummaryDisabled>
              <Styled.Mui.ExpansionPanelDetails>
                <Styled.Mui.ListNoPadding>
                  {orderStore.order.items.map((item, index) => (
                    <React.Fragment
                      key={`OrderItem#${item.orderItemId}-${index}`}
                    >
                      <Mui.Divider />
                      <Container.OrderItemListItemButton
                        restaurantId={orderStore.order!.restaurantId}
                        item={item}
                        arrayIndex={index}
                      />
                    </React.Fragment>
                  ))}
                  <Container.OrderAddMoreListItemButton />
                </Styled.Mui.ListNoPadding>
              </Styled.Mui.ExpansionPanelDetails>
            </Mui.ExpansionPanel>
          </>
        )}
      </>
    );
  }
);

export default OrderOverviewCard;
