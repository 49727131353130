import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as App from "../../app";

interface RestaurantsAppBarBackButtonProps {}

const RestaurantsAppBarBackButton: React.FC<RestaurantsAppBarBackButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);

    const { pathname } = Router.useLocation();
    const isRestaurantsPage = pathname.includes("/mall/");

    return (
      <>
        {isRestaurantsPage && !userStore.isStaff && (
          <>
            <Mui.IconButton
              edge="start"
              color="inherit"
              component={Router.Link}
              to={`/`}
            >
              <Icon.ArrowBack />
            </Mui.IconButton>
          </>
        )}
      </>
    );
  }
);

export default RestaurantsAppBarBackButton;
