import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";
import * as Firebase from "firebase/app";
import "firebase/auth";

interface OrderContinueCheckoutButtonProps {
  disabled: boolean;
}

const OrderContinueCheckoutButton: React.FC<OrderContinueCheckoutButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const toastStore = React.useContext(App.Context.Toast);

    const [user, setUser] = React.useState<Firebase.User | null>(null);

    // const [openUserSignInDialog, setOpenUserSignInDialog] = React.useState<
    //   boolean
    // >(false);
    // const [openMobileOTPDialog, setOpenMobileOTPDialog] = React.useState<
    //   boolean
    // >(false);

    // async function handleContinueCheckoutClick() {
    //   setOpenUserSignInDialog(true);
    // }

    // function handleUserSignInDialogClose(showOTP?: boolean) {
    //   setOpenUserSignInDialog(false);
    //   if (showOTP) {
    //     setOpenMobileOTPDialog(true);
    //     return;
    //   }
    //   createOrder();
    // }

    // function handleMobileOTPDialogClose() {
    //   setOpenMobileOTPDialog(false);
    //   createOrder();
    // }

    Firebase.auth().onAuthStateChanged(user => setUser(user));

    function handleContinueCheckoutClick(user: Firebase.User) {
      return async function() {
        await orderStore.requestCreateOrder(
          user.uid,
          user.phoneNumber as string,
          (userStore.user && userStore.user.email) ||
            userStore.activeUserEmail ||
            null
        );
        await orderStore.processPayment(toastStore);
        // await orderStore.mockRequestCreateOrder(
        //   user.uid,
        //   user.phoneNumber,
        //   user.displayName
        // );
      };
    }

    return (
      <>
        {/* <Component.UserSignInDialog
          open={openUserSignInDialog}
          handleClose={handleUserSignInDialogClose}
        />
        <Component.MobileOTPDialog
          open={openMobileOTPDialog}
          handleClose={handleMobileOTPDialogClose}
        /> */}
        {!userStore.isStaff &&
          user &&
          user.phoneNumber &&
          orderStore.order &&
          (!orderStore.order.status || orderStore.order.status === "created") &&
          orderStore.isOrderEditable && (
            <Mui.Button
              variant="contained"
              fullWidth
              color="primary"
              disabled={orderStore.isOrderCreating || props.disabled}
              onClick={handleContinueCheckoutClick(user)}
            >
              <Component.TwoLabel
                label1={
                  <>
                    {orderStore.isOrderCreating ? (
                      <Mui.CircularProgress />
                    ) : (
                      "Proceed to Pay"
                    )}
                  </>
                }
                label2={<Component.Price price={orderStore.total} />}
              ></Component.TwoLabel>
            </Mui.Button>
          )}
      </>
    );
  }
);

export default OrderContinueCheckoutButton;
