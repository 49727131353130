import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Model from "../../models";
import * as Styled from "../../styles";
import * as Mobx from "mobx-react-lite";
import * as Component from "../../components";
import * as Helper from "../../helpers";

interface CustomizationOptionProps {
  option: Model.Restaurant.Menu.Item.Customization.Option;
  customization: Model.Restaurant.Menu.Item.CuratedCustomization;
  customizations: Map<
    string,
    Map<string, Model.Restaurant.Menu.Item.Customization.Option>
  >;
  setCustomizationOptionChecked(
    option: Model.Restaurant.Menu.Item.Customization.Option,
    customization: Model.Restaurant.Menu.Item.CuratedCustomization
  ): void;
}

const CustomizationOption: React.FC<CustomizationOptionProps> = Mobx.observer(
  props => {
    function handleOptionClick() {
      return function(event: React.SyntheticEvent) {
        event.preventDefault();
        props.setCustomizationOptionChecked(props.option, props.customization);
      };
    }

    // function isDisabled({
    //   customization,
    //   isChecked,
    //   isOnlyOneOptionAllowed,
    //   checkedOptions
    // }: {
    //   customization: Model.Restaurant.Menu.Item.CuratedCustomization;
    //   isChecked: boolean;
    //   isOnlyOneOptionAllowed?: boolean;
    //   checkedOptions?: Map<
    //     string,
    //     Model.Restaurant.Menu.Item.Customization.Option
    //   >;
    // }): boolean {
    //   return !isOnlyOneOptionAllowed &&
    //     !isChecked &&
    //     checkedOptions &&
    //     checkedOptions.size === customization.max
    //     ? true
    //     : false;
    // }

    const isOnlyOneOptionAllowed: boolean = Helper.Restaurant.Menu.Item.Customization.isOnlyOneOptionAllowed(
      props.customization
    );

    const checkedOptions:
      | Map<string, Model.Restaurant.Menu.Item.Customization.Option>
      | undefined = Helper.Restaurant.Menu.Item.Customization.getCheckedOptions(
      props.customization,
      props.customizations
    );

    const checked: boolean = Helper.Restaurant.Menu.Item.Customization.Option.isChecked(
      props.option,
      checkedOptions
    );

    const disabled: boolean = Helper.Restaurant.Menu.Item.Customization.Option.isDisabled(
      {
        customization: props.customization,
        isChecked: checked,
        isOnlyOneOptionAllowed,
        checkedOptions
      }
    );

    return (
      <>
        <Mui.ListItem button disabled={disabled} onClick={handleOptionClick()}>
          <Mui.ListItemText
            disableTypography
            primary={
              <>
                <Mui.Grid container justify="space-between" alignItems="center">
                  <Mui.FormControlLabel
                    control={
                      isOnlyOneOptionAllowed ? (
                        <Mui.Radio checked={checked} />
                      ) : (
                        <Mui.Checkbox disabled={disabled} checked={checked} />
                      )
                    }
                    label={props.option.name}
                    labelPlacement="end"
                  />
                  {props.option.price && (
                    <Styled.Mui.TypographyBold>
                      <Component.Price price={props.option.price} />
                    </Styled.Mui.TypographyBold>
                  )}
                </Mui.Grid>
              </>
            }
          />
        </Mui.ListItem>
      </>
    );
  }
);

export default CustomizationOption;
