import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as Styled from "../../styles";
import * as Sentry from "@sentry/browser";

export interface AppFailedProps {}

export interface AppFailedState {
  hasError?: Error;
  eventId?: string;
}

// TODO: Find if there is a way to show different error messages based on the error properties
// REF: https://stackoverflow.com/questions/51384606/error-boundary-does-not-give-access-to-error-if-it-is-an-instance-of-error-class
class AppFailed extends React.Component<AppFailedProps, AppFailedState> {
  constructor(props: AppFailedProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error);
    console.error(errorInfo);
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  private handleStartFresh() {
    window.location.replace("/");
  }

  render() {
    return this.state.hasError ? (
      <Styled.Page.Container>
        <Mui.Container maxWidth="sm">
          <Component.ErrorInfo picture="/images/app-error.svg">
            <Mui.Grid
              container
              justify="center"
              alignItems="center"
              style={{ marginTop: 16 }}
            >
              <Mui.Button
                variant="contained"
                style={{
                  marginRight: 8
                }}
                onClick={() =>
                  Sentry.showReportDialog({
                    eventId: this.state.eventId
                  })
                }
              >
                Report Feedback
              </Mui.Button>
              <Mui.Button
                color="primary"
                variant="contained"
                autoFocus
                onClick={this.handleStartFresh}
              >
                Start Fresh
              </Mui.Button>
            </Mui.Grid>
          </Component.ErrorInfo>
        </Mui.Container>
      </Styled.Page.Container>
    ) : (
      this.props.children
    );
  }
}

export default AppFailed;
