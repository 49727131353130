/* eslint-disable react-hooks/exhaustive-deps */
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as ReactFire from "reactfire";
import * as App from "../../app";
import * as Component from "../../components";
import * as Model from "../../models";

interface QrLinkProcessorProps {}

const QrLinkProcessor: React.FC<QrLinkProcessorProps> = Mobx.observer(
  (props) => {
    const { restaurantId, roomNumber } = Router.useParams<
      Model.RouteParams.QrLinkProcessor
    >();

    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

    const restaurantRef = ReactFire.useFirebaseApp()
      .firestore()
      .collection("restaurants")
      .doc(restaurantId);

    React.useEffect(() => {
      const timestamp = new Date().getTime();
      restaurantStore.subscribe(restaurantRef);
      restaurantStore.setRoom({ roomNumber, timestamp });
      return () => {
        const order = localStorage.getItem(`order@${restaurantId}`);

        if (!order) {
          localStorage.clear();
        }

        localStorage.setItem(
          `room@${restaurantId}`,
          JSON.stringify({
            roomNumber,
            timestamp,
          })
        );
      };
    }, []);

    React.useEffect(() => {}, [restaurantStore]);

    return (
      <>
        {restaurantStore.room.roomNumber && restaurantStore.room.timestamp && (
          <Router.Redirect to={`/restaurant/${restaurantId}/menu`} />
        )}
      </>
    );
  }
);

export default Component.withTracker(QrLinkProcessor);
