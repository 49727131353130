import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as Styled from "../../styles";
import * as App from "../../app";

interface MenuItemInstructionInputCardProps {
  maxAllowedCharacters?: number;
}

const MenuItemInstructionInputCard: React.FC<MenuItemInstructionInputCardProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.value.length > (props.maxAllowedCharacters as number)) {
        return;
      }

      menuItemStore.setInstruction(event.target.value);
    }

    return (
      <>
        {!userStore.isStaff && menuItemStore.menuItem && (
          <Mui.ExpansionPanel expanded={true}>
            <Styled.Mui.ExpansionPanelSummary>
              <Mui.Grid container justify="space-between" alignItems="baseline">
                <Mui.Typography variant="h6">Instructions</Mui.Typography>
                <Mui.Typography variant="body2" color="textSecondary">
                  {menuItemStore.instruction.length} /{" "}
                  {props.maxAllowedCharacters as number} characters
                </Mui.Typography>
              </Mui.Grid>
            </Styled.Mui.ExpansionPanelSummary>
            <Mui.Divider />
            <Styled.Mui.ExpansionPanelDetailsDensePadding>
              <Mui.TextField
                placeholder={`Max ${props.maxAllowedCharacters} characters allowed.`}
                variant="outlined"
                fullWidth
                multiline
                value={menuItemStore.instruction}
                onChange={handleChange}
              />
              <Mui.FormHelperText>
                List any special requests you may have (ie. allergies, extra
                spicy, etc.)
              </Mui.FormHelperText>
            </Styled.Mui.ExpansionPanelDetailsDensePadding>
          </Mui.ExpansionPanel>
        )}
      </>
    );
  }
);

MenuItemInstructionInputCard.defaultProps = {
  maxAllowedCharacters: 160
};

export default MenuItemInstructionInputCard;
