import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "../../app";
import * as Component from "../../components";
import * as Util from "../../utils";

interface OrderCheckoutButtonProps {}

const OrderCheckoutButton: React.FC<OrderCheckoutButtonProps> = Mobx.observer(
  (props) => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const orderStore = React.useContext(App.Context.Restaurant.Order);

    function testRoom(): boolean {
      if (!restaurantStore.room.roomNumber || !restaurantStore.room.timestamp) {
        return false;
      }

      const now = new Date().getTime();
      const limit = new Date(restaurantStore.room.timestamp).setHours(
        new Date(restaurantStore.room.timestamp).getHours() + 3
      );
      if (now < limit) {
        return true;
      }
      return false;
    }

    return (
      <>
        {!userStore.isStaff &&
          orderStore.order &&
          orderStore.order.items.length > 0 &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId && (
            <Mui.Button
              color="primary"
              variant="contained"
              fullWidth
              component={Router.Link}
              disabled={
                orderStore.operatingHours &&
                !Util.Restaurant.isOpen(orderStore.operatingHours)
              }
              to={`/order`}
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => {
                if (!testRoom()) {
                  event.preventDefault();
                }
              }}
            >
              <Component.TwoLabel
                label1={"Checkout Order"}
                label2={<Component.Price price={orderStore.subTotal} />}
              />
            </Mui.Button>
          )}
      </>
    );
  }
);

export default OrderCheckoutButton;
