import * as Model from "../../../models";
import * as Mobx from "mobx";
import * as Firebase from "firebase/app";
import "firebase/firestore";

interface EngagementLayerTemplate {
    id?: string;
    name: string;
    video: string;
    image: string;
  }

export class EngagementLayer {

    @Mobx.observable public templates: EngagementLayerTemplate[] = [];

    @Mobx.action public getTemplates(templateRef: Firebase.firestore.CollectionReference) {
        if(this.templates.length > 0 ) {
            return;
        }
        console.log("Fetching data");
        templateRef.get().then((querySnapshot: any) => {
            if (querySnapshot.empty) {
              return;
            }
            
            querySnapshot.docs.forEach((docSnapShot: any) => {
              this.templates.push({ id: docSnapShot.id, ...docSnapShot.data() } as EngagementLayerTemplate);
            });
            console.log("DONE FETCHING DATA");
          });
      }

}