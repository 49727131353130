import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as ReactFire from "reactfire";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as App from "../../app";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Edit";
// import ArrowRightIcon from "@material-ui/icons/ArrowRight";

interface EngagementLayerTemplate {
  id?: string;
  name: string;
  video: string;
  image: string;
}

const EngagementLayer: React.FC<any> = Mobx.observer((props) => {
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);

  const engagementLayerStore = React.useContext(App.Context.Restaurant.Menu.EngagementLayer);

  const [templateId, setTemplateId] = React.useState("");
  const [templateName, setTemplateName] = React.useState("");
  const [video, setVideo] = React.useState("");
  const [image, setImage] = React.useState("");

  const [templates, setTemplates] = React.useState([] as EngagementLayerTemplate[]);
  const engagementLayerCollRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("pro-menu")
    .doc("engagement_layer")
    .collection("templates");

  const openModelForUpdate = (data: EngagementLayerTemplate) => {
    console.log(data);
    setTemplateId(data.id || "");
    setTemplateName(data.name);
    setImage(data.image);
    setVideo(data.video);
    setOpen(true);
    setUpdate(true);
  };

  const getTemplates = () => {
    engagementLayerStore.getTemplates(engagementLayerCollRef);
    /* engagementLayerCollRef.get().then((querySnapshot: any) => {
      if (querySnapshot.empty) {
        return;
      }
      const templates = [] as EngagementLayerTemplate[];
      querySnapshot.docs.forEach((docSnapShot: any) => {
        templates.push({ id: docSnapShot.id, ...docSnapShot.data() } as EngagementLayerTemplate);
      });
      setTemplates(templates);
    }); */
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUpdate(false);
    setOpen(false);
  };

  const changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(event.target.value);
  };

  const changeVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVideo(event.target.value);
  };

  const changeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImage(event.target.value);
  };

  const handleTemplateSave = () => {
    if (templateName.trim() === "" || image.trim() === "") {
      alert("Template Name / Image cant be Blank");
    }
    if (update && templateId && templateId.trim() !== "") {
      // DO The Update
      engagementLayerCollRef.doc(templateId).update({
        name: templateName,
        video,
        image,
      });
      setUpdate(false);
      setOpen(false);
      return;
    }
    // Do the Add
    engagementLayerCollRef.add({
      name: templateName,
      video,
      image,
    });
    setOpen(false);
  };
  return (
    <>
      <Mui.Grid container>
        <Mui.Grid xs={12}>
          <Mui.Button variant="outlined" color="primary" onClick={getTemplates}>
            Fetch Templates
          </Mui.Button>
          &nbsp;
          <Mui.Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Add Template
          </Mui.Button>
        </Mui.Grid>
        <Mui.Grid xs={12}>
          <Mui.List>
            {engagementLayerStore.templates.map((template, index) => (
              <div key={`listItem${index}`}>
                <Mui.Divider />
                <Mui.ListItem button>
                  <Mui.ListItemText primary={template.name} onClick={() => openModelForUpdate(template)} />
                  <Mui.ListItemSecondaryAction>
                    <Mui.IconButton edge="end" aria-label="delete" onClick={() => openModelForUpdate(template)}>
                      <UpdateIcon />
                    </Mui.IconButton>
                  </Mui.ListItemSecondaryAction>
                </Mui.ListItem>
              </div>
            ))}
          </Mui.List>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Mui.AppBar>
          <Mui.Toolbar>
            <Mui.IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </Mui.IconButton>
            <Mui.Typography variant="h6">{update ? `Update Template` : `Create Template`}</Mui.Typography>
          </Mui.Toolbar>
        </Mui.AppBar>
        <Mui.DialogTitle id="form-dialog-title">Subscribe</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.TextField
            autoFocus
            margin="dense"
            id="name"
            label="Template Name"
            type="email"
            fullWidth
            value={templateName}
            onChange={changeName}
          />
          <Mui.TextField
            autoFocus
            margin="dense"
            id="name"
            label="Video Playback"
            type="email"
            fullWidth
            value={video}
            onChange={changeVideo}
          />
          <Mui.TextField
            autoFocus
            margin="dense"
            id="name"
            label="Image Template"
            type="email"
            fullWidth
            value={image}
            onChange={changeImage}
          />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleClose} color="primary">
            Cancel
          </Mui.Button>
          <Mui.Button onClick={handleTemplateSave} color="primary">
            {update ? `Update` : `Add`}
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
});
export default EngagementLayer;
