/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as Container from "../../containers";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";
import * as Model from "../../models";
import * as ReactFire from "reactfire";

interface ItemProps {}

const Item: React.FC<ItemProps> = Mobx.observer((props) => {
  const { menuItemId, restaurantId } = Router.useParams<Model.RouteParams.Item>();

  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const menuStore = React.useContext(App.Context.Restaurant.Menu.Menu);
  const menuItemStore = React.useContext(App.Context.Restaurant.Menu.Item.Item);

  const restaurantRef = ReactFire.useFirebaseApp().firestore().collection("restaurants").doc(restaurantId);
  const menuRef = restaurantRef.collection("menu");
  const menuItemRef = menuRef.doc(menuItemId);

  React.useEffect(() => {
    restaurantStore.subscribe(restaurantRef);
    menuStore.subscribe(menuRef, restaurantRef);
    menuItemStore.subscribe(menuItemRef);
    return () => {
      menuItemStore.reset();
    };
  }, []);

  return (
    <>
      {menuItemStore.isFetching ? (
        <Component.PageLoadingIndicator />
      ) : (
        <>
          <Styled.Page.Container>
            {menuItemStore.menuItem && (
              <>
                <Mui.Grid container justify="flex-start" alignItems="center">
                  <Container.BackButton />
                </Mui.Grid>
                <Component.ItemInfo
                  available={menuItemStore.menuItem.available}
                  name={menuItemStore.menuItem.name}
                  price={menuItemStore.menuItem.price}
                  description={menuItemStore.menuItem.description}
                  tags={menuItemStore.menuItem.tags}
                  variant={Component.ITEM_INFO_VARIANT.HERO}
                />
                {menuItemStore.menuItem.extraDescription && (
                  <Mui.Typography variant="body2" color="textSecondary">
                    <Mui.Typography component="span" color="error">
                      *&nbsp;
                    </Mui.Typography>
                    {menuItemStore.menuItem.extraDescription}
                  </Mui.Typography>
                )}
                <Container.StaffMenuItemAvailabilityCard />
                <Container.StaffMenuItemTagsCard />
                <Container.StaffMenuItemHide />
                <Container.StaffMenuItemType />
                <Container.MenuItemQuantitySelectCard />
                {menuItemStore.menuItem.requiredCustomization &&
                  menuItemStore.menuItem.requiredCustomization.map((customization, index) => (
                    <Container.MenuItemCustomizationCard
                      key={`RequiredCustomization#${index}-${customization.requiredCustomizationId}`}
                      customization={customization}
                      arrayIndex={index}
                    />
                  ))}
                {menuItemStore.menuItem.addOnCustomization &&
                  menuItemStore.menuItem.addOnCustomization.map((customization, index) => (
                    <Container.MenuItemCustomizationCard
                      key={`AddOnCustomization#${index}-${customization.addOnCustomizationId}`}
                      customization={customization}
                      arrayIndex={index}
                    />
                  ))}
                <Container.MenuItemInstructionInputCard />
                <Component.FixedFooter>
                  <Mui.Grid container spacing={1}>
                    <Mui.Grid item xs>
                      <Container.MenuItemUpdateOrderButton />
                    </Mui.Grid>
                    <Mui.Grid item xs={3} style={{ maxWidth: "max-content" }}>
                      <Container.MenuItemDeleteFromOrderIconButton />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Container.MenuItemAddToOrderButton />
                  <Container.StaffMenuItemEditButton />
                </Component.FixedFooter>
              </>
            )}
          </Styled.Page.Container>
        </>
      )}
    </>
  );
});

export default Component.withTracker(Item);
