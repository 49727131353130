import * as React from "react";
import * as Model from "../../models";
import * as Mobx from "mobx-react-lite";
import * as Component from "../../components";
import * as App from "../../app";

interface MenuItemCustomizationOptionListItemButtonProps {
  option: Model.Restaurant.Menu.Item.Customization.Option;
  customization: Model.Restaurant.Menu.Item.CuratedCustomization;
}

const MenuItemCustomizationOptionListItemButton: React.FC<MenuItemCustomizationOptionListItemButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);

    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );

    return (
      <>
        {!userStore.isStaff && (
          <Component.CustomizationOption
            option={props.option}
            customization={props.customization}
            customizations={menuItemStore.customizations}
            setCustomizationOptionChecked={
              menuItemStore.setCustomizationOptionChecked
            }
          />
        )}
      </>
    );
  }
);

export default MenuItemCustomizationOptionListItemButton;
