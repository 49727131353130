import * as Mobx from "mobx";
import * as Model from "../../models";
import * as Firebase from "firebase/app";
import "firebase/auth";

export class Restaurant {
  @Mobx.observable public restaurants: Model.Restaurant.Restaurant[] = [];
  @Mobx.observable public isFetching: boolean = true;
  private activeMallId?: string;

  @Mobx.action public subscribe(
    restaurantsRef: Firebase.firestore.CollectionReference,
    mallId: string
  ) {
    if (this.activeMallId === mallId) {
      return;
    }

    this.activeMallId = mallId;

    const unsubscribe: () => void = restaurantsRef
      .where("mallId", "==", mallId)
      .onSnapshot(
        snapshot => {
          const restaurants: Model.Restaurant.Restaurant[] = snapshot.docs.map(
            doc => {
              return {
                restaurantId: doc.id,
                ...doc.data()
              } as Model.Restaurant.Restaurant;
            }
          );
          this.restaurants = restaurants;
          this.isFetching = false;
        },
        (error: Error) => {
          console.error(error);
          unsubscribe();
        },
        () => unsubscribe()
      );
  }
}
