import * as Mui from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import * as React from "react";
import * as Component from "../../components";
import * as Model from "../../models";
import * as Styled from "../../styles";

interface OrderInfoListItemTextProps {
  order: Model.Restaurant.Order.Order;
}

const OrderInfoListItemText: React.FC<OrderInfoListItemTextProps> = (props) => {
  return (
    <>
      <Mui.ListItemText
        disableTypography
        primary={
          <>
            <Mui.Grid container justify="flex-start" alignItems="center">
              {props.order.orderId && (
                <Styled.Mui.TypographyBold variant="h5">
                  # {props.order.orderId.slice(-5).toUpperCase()}
                </Styled.Mui.TypographyBold>
              )}
              {props.order.roomNumber && (
                <Mui.Button
                  variant="outlined"
                  startIcon={<Icons.MeetingRoom />}
                  style={{ marginLeft: 16 }}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  // size="small"
                >
                  <Mui.Box fontWeight="bold">{props.order.roomNumber}</Mui.Box>
                </Mui.Button>
              )}
            </Mui.Grid>
          </>
        }
        secondary={
          <>
            {props.order.total && <Component.Price price={props.order.total} />}
            {" | "}
            {props.order.items
              .map((item) => {
                return `${item.name}`;
              })
              .join(", ")}
          </>
        }
      />
    </>
  );
};

export default OrderInfoListItemText;
