/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import * as Mobx from "mobx-react-lite";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as Container from "../../containers";
import * as Router from "react-router-dom";
import * as Model from "../../models";
import * as ReactFire from "reactfire";
import * as App from "../../app";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = Mobx.observer((props) => {
  const { restaurantId } = Router.useParams<Model.RouteParams.Menu>();

  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

  const restaurantRef = ReactFire.useFirebaseApp().firestore().collection("restaurants").doc(restaurantId);
  const [open, setOpen] = React.useState(false);
  const [startTime, setStartTime] = React.useState(`00:00:00`);
  const [endTime, setEndTime] = React.useState(`23:59:00`);

  const handleClickOpen = () => {
    restaurantStore.editOperatingHours();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOperatingHoursSave = async () => {
    await restaurantRef.update({ operatingHours: restaurantStore.operatingHoursEdit });
    handleClose();
  };

  const [weekday, setWeekDay] = React.useState(`monday`);
  const handleWeekdayChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!event.target.value) {
      return;
    }
    setWeekDay(event.target.value as string);
  };

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(`${event.target.value}:00`);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(`${event.target.value}:00`);
  };

  const handleRemoveDay = (day: string) => {
    const weekday = day as Model.Restaurant.Days;
    restaurantStore.deleteOperatingHourForDay(weekday);
  };
  const handleTimeAdd = () => {
    console.log(weekday, startTime, endTime);
    const startTimeParts = startTime.split(":");
    const endTimeParts = endTime.split(":");
    if (startTimeParts[0] > endTimeParts[0]) {
      // end time is less than start time
      return;
    } else if (startTimeParts[0] === endTimeParts[0] && startTimeParts[1] >= endTimeParts[1]) {
      // End time is less than start time
      return;
    }
    restaurantStore.addOperatingHoursForDay(weekday as Model.Restaurant.Days, { startTime, endTime });
  };

  React.useEffect(() => {
    // restaurantStore.subscribe(restaurantId);
    // menuStore.subscribe(restaurantId);
    restaurantStore.subscribe(restaurantRef);
    // NOT NEEDED IN SETTINGS menuStore.subscribe(menuRef, restaurantRef);
  }, []);

  return (
    <>
      {restaurantStore.isFetching ? (
        <Component.PageLoadingIndicator />
      ) : (
        <>
          <Component.RestaurantCarousel
            images={
              restaurantStore.images && restaurantStore.images.length > 0
                ? restaurantStore.images
                : [
                    {
                      name: "image",
                      active: true,
                      url:
                        "https://firebasestorage.googleapis.com/v0/b/restaurant-36d1e.appspot.com/o/Restaurant%2Fhouse22.jpeg?alt=media&token=8fbb1ad0-4c54-4a6d-9237-058e78316c4f",
                    },
                  ]
            }
          />
          <Styled.Menu.MenuContainer>
            <Mui.Card>
              <Mui.CardContent>
                <Mui.Grid container spacing={2}>
                  <Mui.Grid item xs={12} sm container>
                    <Mui.Grid item xs container direction="column" spacing={2}>
                      <Mui.Grid item xs>
                        <Mui.Typography gutterBottom variant="subtitle1">
                          {restaurantStore && restaurantStore.restaurant && restaurantStore.restaurant.name}
                        </Mui.Typography>
                        <Mui.Typography variant="body2" gutterBottom>
                          {restaurantStore && restaurantStore.restaurant && restaurantStore.restaurant.cuisines}
                        </Mui.Typography>
                        <Mui.Typography variant="body2" color="textSecondary">
                          {restaurantStore && restaurantStore.restaurant && restaurantStore.restaurant.address}
                        </Mui.Typography>
                        <Mui.Typography variant="body2" color="textSecondary">
                          {restaurantStore && restaurantStore.restaurant && restaurantStore.restaurant.city}, &nbsp;
                          {restaurantStore && restaurantStore.restaurant && restaurantStore.restaurant.country}
                        </Mui.Typography>
                        <Mui.Typography variant="body2" color="textSecondary">
                          {restaurantStore && restaurantStore.restaurant && restaurantStore.restaurant.pincode}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item>
                      <Mui.Typography variant="subtitle1">
                        <MuiIcons.Edit />
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.CardContent>
            </Mui.Card>
            <br />
            <Mui.ExpansionPanel>
              <Mui.ExpansionPanelSummary
                expandIcon={<MuiIcons.ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Mui.Typography>Operating Hours</Mui.Typography>
              </Mui.ExpansionPanelSummary>
              <Mui.ExpansionPanelDetails>
                {restaurantStore && restaurantStore.restaurant && restaurantStore.restaurant.operatingHours && (
                  <Mui.Grid container>
                    <Mui.Grid item xs={3}>
                      <Mui.Typography>Mon</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container xs={9}>
                      {restaurantStore.restaurant.operatingHours.monday.map((timings) => (
                        <>
                          <Mui.Typography>{`${timings.startTime}-${timings.endTime}`}</Mui.Typography>
                          &nbsp;&nbsp;
                        </>
                      ))}
                    </Mui.Grid>
                    <Mui.Grid item xs={3}>
                      <Mui.Typography>Tue</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container xs={9}>
                      {restaurantStore.restaurant.operatingHours.tuesday.map((timings) => (
                        <>
                          <Mui.Typography>{`${timings.startTime}-${timings.endTime}`}</Mui.Typography>
                          &nbsp;&nbsp;
                        </>
                      ))}
                    </Mui.Grid>
                    <Mui.Grid item xs={3}>
                      <Mui.Typography>Wed</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container xs={9}>
                      {restaurantStore.restaurant.operatingHours.wednesday.map((timings) => (
                        <>
                          <Mui.Typography>{`${timings.startTime}-${timings.endTime}`}</Mui.Typography>
                          &nbsp;&nbsp;
                        </>
                      ))}
                    </Mui.Grid>
                    <Mui.Grid item xs={3}>
                      <Mui.Typography>Thu</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container xs={9}>
                      {restaurantStore.restaurant.operatingHours.thursday.map((timings) => (
                        <>
                          <Mui.Typography>{`${timings.startTime}-${timings.endTime}`}</Mui.Typography>
                          &nbsp;&nbsp;
                        </>
                      ))}
                    </Mui.Grid>
                    <Mui.Grid item xs={3}>
                      <Mui.Typography>Fri</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container xs={9}>
                      {restaurantStore.restaurant.operatingHours.friday.map((timings) => (
                        <>
                          <Mui.Typography>{`${timings.startTime}-${timings.endTime}`}</Mui.Typography>
                          &nbsp;&nbsp;
                        </>
                      ))}
                    </Mui.Grid>
                    <Mui.Grid item xs={3}>
                      <Mui.Typography>Sat</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container xs={9}>
                      {restaurantStore.restaurant.operatingHours.saturday.map((timings) => (
                        <>
                          <Mui.Typography>{`${timings.startTime}-${timings.endTime}`}</Mui.Typography>
                          &nbsp;&nbsp;
                        </>
                      ))}
                    </Mui.Grid>
                    <Mui.Grid item xs={3}>
                      <Mui.Typography>Sun</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container xs={9}>
                      {restaurantStore.restaurant.operatingHours.sunday.map((timings) => (
                        <>
                          <Mui.Typography>{`${timings.startTime}-${timings.endTime}`}</Mui.Typography>
                          &nbsp;&nbsp;
                        </>
                      ))}
                    </Mui.Grid>
                  </Mui.Grid>
                )}
                <Mui.CardActions disableSpacing>
                  <Mui.IconButton aria-label="edit">
                    <MuiIcons.Edit onClick={handleClickOpen} />
                  </Mui.IconButton>
                </Mui.CardActions>
              </Mui.ExpansionPanelDetails>
            </Mui.ExpansionPanel>
            <Mui.Dialog open={open} onClose={handleClose}>
              <Styled.Menu.MenuContainer>
                <Mui.DialogTitle id="customized-dialog-title">Modify Operating Hours</Mui.DialogTitle>
                <form noValidate>
                  <Mui.FormControl>
                    <Mui.InputLabel id="demo-simple-select-label">Age</Mui.InputLabel>
                    <Mui.Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={weekday}
                      fullWidth
                      onChange={handleWeekdayChange}
                    >
                      <Mui.MenuItem value={`monday`}>Monday</Mui.MenuItem>
                      <Mui.MenuItem value={`tuesday`}>Tuesday</Mui.MenuItem>
                      <Mui.MenuItem value={`wednesday`}>Wednesday</Mui.MenuItem>
                      <Mui.MenuItem value={`thursday`}>Thursday</Mui.MenuItem>
                      <Mui.MenuItem value={`friday`}>Friday</Mui.MenuItem>
                      <Mui.MenuItem value={`saturday`}>Saturday</Mui.MenuItem>
                      <Mui.MenuItem value={`sunday`}>Sunday</Mui.MenuItem>
                    </Mui.Select>
                  </Mui.FormControl>
                  <Mui.TextField
                    id="time"
                    label="Start time"
                    type="time"
                    defaultValue="00:00"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 1800, // 30 min
                    }}
                    onChange={handleStartTimeChange}
                  />
                  <Mui.TextField
                    id="time"
                    label="End time"
                    type="time"
                    fullWidth
                    defaultValue="23:59"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 1800, // 30 min
                    }}
                    onChange={handleEndTimeChange}
                  />
                  <Mui.Button autoFocus variant="contained" color="primary" onClick={handleTimeAdd}>
                    Add
                  </Mui.Button>
                </form>
                <Mui.Grid item xs={12}>
                  <Mui.Divider />
                </Mui.Grid>
                {restaurantStore && restaurantStore.restaurant && restaurantStore.operatingHoursEdit && (
                  <Mui.Grid container>
                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Mon</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {restaurantStore.operatingHoursEdit.monday.length > 0 ? (
                              restaurantStore.operatingHoursEdit.monday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                CLOSED
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`monday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Tue</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {restaurantStore.operatingHoursEdit.tuesday.length > 0 ? (
                              restaurantStore.operatingHoursEdit.tuesday.map((timings) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                CLOSED
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`tuesday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Wed</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {restaurantStore.operatingHoursEdit.wednesday.length > 0 ? (
                              restaurantStore.operatingHoursEdit.wednesday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                CLOSED
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`wednesday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Thu</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {restaurantStore.operatingHoursEdit.thursday.length > 0 ? (
                              restaurantStore.operatingHoursEdit.thursday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                CLOSED
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`thursday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Fri</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {restaurantStore.operatingHoursEdit.friday.length > 0 ? (
                              restaurantStore.operatingHoursEdit.friday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                CLOSED
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`friday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Sat</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {restaurantStore.operatingHoursEdit.saturday.length > 0 ? (
                              restaurantStore.operatingHoursEdit.saturday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                CLOSED
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`saturday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>

                    <Mui.Grid container spacing={2}>
                      <Mui.Grid item xs={3}>
                        <Mui.Typography>Sun</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} sm container>
                        <Mui.Grid item xs container direction="column" spacing={2}>
                          <Mui.Grid item xs>
                            {restaurantStore.operatingHoursEdit.sunday.length > 0 ? (
                              restaurantStore.operatingHoursEdit.sunday.map((timings, index) => (
                                <Mui.Typography variant="body2" gutterBottom>
                                  {`${timings.startTime}-${timings.endTime}`}
                                </Mui.Typography>
                              ))
                            ) : (
                              <Mui.Typography variant="body2" gutterBottom>
                                CLOSED
                              </Mui.Typography>
                            )}
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid item>
                          <Mui.Button
                            color="secondary"
                            onClick={() => {
                              handleRemoveDay(`sunday`);
                            }}
                          >
                            Remove
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Divider />
                    </Mui.Grid>
                  </Mui.Grid>
                )}
              </Styled.Menu.MenuContainer>
              <Mui.DialogActions>
                <Mui.Button autoFocus onClick={handleOperatingHoursSave} color="primary">
                  Save changes
                </Mui.Button>
              </Mui.DialogActions>
            </Mui.Dialog>
          </Styled.Menu.MenuContainer>
        </>
      )}
    </>
  );
});

export default Component.withTracker(Settings);
