import * as React from "react";
import * as ReactFire from "reactfire";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Model from "../../models";
import "firebase/firestore";

interface StaffMenuEditSaveButtonProps {}

const StaffMenuEditSaveButton: React.FC<StaffMenuEditSaveButtonProps> = Mobx.observer(
  props => {
    const firebaseApp = ReactFire.useFirebaseApp();
    const restaurantsRef = firebaseApp.firestore().collection("restaurants");

    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const categoryEditStore = React.useContext(
      App.Context.Restaurant.Category.Edit
    );
    const menuEditStore = React.useContext(App.Context.Restaurant.Menu.Edit);

    function handleClick(restaurant: Model.Restaurant.Restaurant) {
      return async function() {
        const categories: Model.Restaurant.Categories =
          categoryEditStore.asRestaurantCategories;
        const { restaurantId, ...restOfRestaurant } = restaurant;
        await restaurantsRef.doc(restaurantId as string).update({
          ...restOfRestaurant,
          categories
        } as Model.Restaurant.Restaurant);

        categoryEditStore.reset();
        menuEditStore.reset();
      };
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          (menuEditStore.isEditable || categoryEditStore.isEditable) && (
            <Mui.Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleClick(restaurantStore.restaurant)}
            >
              Save
            </Mui.Button>
          )}
      </>
    );
  }
);

export default StaffMenuEditSaveButton;
