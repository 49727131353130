/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as ReactFire from "reactfire";
import * as Router from "react-router-dom";
import * as Styled from "../../styles";
import * as Model from "../../models";
import * as App from "../../app";
import * as Container from "../../containers";
import * as Component from "../../components";
import Papa from "papaparse";
import uuidv4 from "uuid/v4";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Mui from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { EngagementLayer } from "../EngagementLayer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

interface OrdersProps {}

interface RestaurantList {
  restaurantId: string;
  restaurantName: string;
  location: string;
}

const UploadRestaurant: React.FC<OrdersProps> = Mobx.observer((props) => {
  const classes = useStyles();
  const map = new Map();
  const { restaurantId } = Router.useParams<Model.RouteParams.Orders>();
  const history = Router.useHistory();
  const userStore = React.useContext(App.Context.User.User);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const menuStore = React.useContext(App.Context.Restaurant.Menu.Menu);
  const staffOrdersStore = React.useContext(App.Context.Staff.Order);

  const [restaurantData, setRestaurantData] = React.useState({} as any);
  const [menuData, setMenuData] = React.useState([]);
  const [enableUpload, setEnableUpload] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [searchId, setSearchId] = React.useState("");
  const [restaurants, setRestaurants] = React.useState([] as RestaurantList[]);

  const restaurantCollectionRef = ReactFire.useFirebaseApp().firestore().collection("restaurants");
  const promenuRestaurantsRef = ReactFire.useFirebaseApp().firestore().collection("pro-menu").doc("restaurant_details");

  const realTimeDatabaseRef = ReactFire.useFirebaseApp().database().ref("restaurants/-L4AoY15n5FYrWO1_irb/menu");
  // var postsRef = dbrealtime.ref("restaurants/-L4AoY15n5FYrWO1_irb/menu");
  // const restaurantRef = ReactFire.useFirebaseApp()
  //   .firestore()
  //   .collection("restaurants")
  //   .doc(restaurantId);

  // const ordersRef = ReactFire.useFirebaseApp()
  //   .firestore()
  //   .collection("orders");

  // const menuRef = restaurantRef.collection("menu");

  // React.useEffect(() => {
  // restaurantStore.subscribe(restaurantRef);
  //   // menuStore.subscribe(menuRef, restaurantRef);
  //   // staffOrdersStore.subscribe(ordersRef, restaurantId);
  // }, []);

  const handleSubmit = async () => {
    setProgress(true);
    setEnableUpload(false);
    const result = await restaurantStore.createRestaurantData(restaurantCollectionRef, restaurantData, menuData);
    if (result) {
      let restaurants: any = {};
      restaurants[restaurantData.restaurantId] = {
        restaurantId: restaurantData.restaurantId,
        restaurantName: restaurantData.name,
        location: restaurantData.address,
      };
      await promenuRestaurantsRef.set(
        {
          restaurants,
        },
        { merge: true }
      );
      history.push(`/restaurant/${restaurantData.restaurantId}/menu`);
    }
  };

  const openRestaurant = (restaurantId: string) => {
    history.push(`/restaurant/${restaurantId}/menu`);
  };
  const searchRestaurant = async () => {
    console.log(searchId);
    if (!searchId || searchId.trim() === "") {
      alert("Enter Restaurant Id to search");
      return;
    }
    setProgress(true);
    setEnableUpload(false);
    const available = await restaurantStore.checkRestaurantExists(restaurantCollectionRef, searchId.trim());
    if (!available) {
      alert("No restaurant exists for the search");
      setProgress(false);
    } else {
      history.push(`/restaurant/${searchId.trim()}/menu`);
    }
  };

  const handleInputChange = (event: any) => {
    setSearchId(event.target.value);
  };

  const fetchRestaurants = () => {
    if (restaurants.length > 0) {
      return;
    }
    promenuRestaurantsRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        const docData = docSnapshot.data();
        if (docData && docData.restaurants) {
          const restaurants: RestaurantList[] = [] as RestaurantList[];
          const restaurantsData = docData.restaurants;
          const restaurantIds = Object.keys(restaurantsData);
          restaurantIds.forEach((restaurantId) => {
            restaurants.push(restaurantsData[restaurantId] as RestaurantList);
          });
          setRestaurants(restaurants);
          console.log(docData.restaurants);
        }
      }
    });
  };
  const handleChange = (event: any) => {
    Papa.parse(event.target.files[0], {
      complete: async (menuItems: any) => {
        let restaurantData: any = {
          city: "Mumbai",
          country: "India",
          currency: {
            code: "INR",
            symbol: "₹",
            isIRD: false,
            isLive: false,
            isOutlet: true,
            isPureVeg: false,
            isStandalone: true,
          },
          operatingHours: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
          },
          orderPayment: {
            cod: false,
            withPayment: true,
            withoutPayment: false,
          },
          pincode: "600061",
          taxRateName: "GST 5%",
          taxRate: 5,
          thumbnail:
            "https://firebasestorage.googleapis.com/v0/b/restaurant-36d1e.appspot.com/o/restaurant_default.jpg?alt=media&token=93ba6995-afa9-41ed-a9f6-4b29545b9caa",
        };
        let menuData: any = [];
        let categoryMap = new Map();
        let categories: any = {};
        let displayOrder: number = 0;
        //console.log(menuItems);
        menuItems.data.forEach((menuItem: any, index: any) => {
          if (index === 0) {
            restaurantData.restaurantId = menuItem[0];
            restaurantData.name = menuItem[1];
            restaurantData.address = menuItem[2] || "";
            restaurantData.statutoryWarning = menuItem[3] || "";
            restaurantData.generalText = menuItem[4] || "";
          } else if (index !== 1) {
            const name = menuItem[1] || "";
            const description = menuItem[2] || "";
            const extraDescription = menuItem[3] || "";
            const category = menuItem[4] || "";
            const price = menuItem[5] || "";
            const tag = menuItem[6] || "";
            const availability = menuItem[7] || "Available";
            const type = menuItem[8] || "";

            if (!categoryMap.has(category)) {
              const categoryId = uuidv4();
              categories[categoryId] = {
                name: category,
                displayOrder: displayOrder++,
              };
              categoryMap.set(category, categoryId);
            }

            let available = false;
            if (availability === "Available") {
              available = true;
            }

            const postId = realTimeDatabaseRef.push().key;
            menuData.push({
              name,
              category,
              description,
              displayOrder: 0,
              available,
              tags: [tag.toLowerCase()],
              price: parseInt(price),
              type,
              extraDescription,
              categoryId: categoryMap.get(category) || "",
              id: postId,
            });
          }
        });
        restaurantData.categories = categories;
        console.log(restaurantData);
        setRestaurantData(restaurantData);
        setMenuData(menuData);

        const available = await restaurantStore.checkRestaurantExists(
          restaurantCollectionRef,
          restaurantData.restaurantId
        );
        if (!available) {
          setEnableUpload(true);
        } else {
          alert("Restaurant Already Exists try different restaurant ID");
        }
      },
    });
  };

  const orders: Model.Restaurant.Order.Order[] =
    staffOrdersStore[`${staffOrdersStore.activeFilter}Orders` as "allOrders" | "preparingOrders" | "readyOrders"];

  return (
    <>
      {!userStore.isStaff && <Router.Redirect to="/" />}
      {userStore.isStaff && !userStore.isAdmin && <Router.Redirect to="/" />}

      <Mui.ExpansionPanel>
        <Mui.ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Mui.Typography>Add Restaurant</Mui.Typography>
        </Mui.ExpansionPanelSummary>
        <Mui.ExpansionPanelDetails>
          <div>
            <p>Upload data as CSV for creating new restaurant</p>
            <form>
              <input accept=".csv" type="file" onChange={handleChange} />
              {enableUpload && (
                <div>
                  Restaurant Id: {restaurantData.restaurantId}
                  <br />
                  Restaurant Name: {restaurantData.name}
                  <br />
                  Restaurant address: {restaurantData.address}
                  <br />
                  Total items: {menuData.length};
                  <br />
                  <input type="button" onClick={handleSubmit} value="Create Restaurant" />
                </div>
              )}
              {progress && <CircularProgress />}
            </form>
          </div>
        </Mui.ExpansionPanelDetails>
      </Mui.ExpansionPanel>
      <Mui.ExpansionPanel>
        <Mui.ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
          <Mui.Typography>Search Restaurant</Mui.Typography>
        </Mui.ExpansionPanelSummary>
        <Mui.ExpansionPanelDetails>
          <div>
            <form>
              <div>
                <input type="input" onChange={handleInputChange} />
                &nbsp;&nbsp;&nbsp;
                <input type="button" onClick={searchRestaurant} value="Search Restaurant" placeholder="restaurantId" />
                {progress && <CircularProgress />}
              </div>
            </form>
          </div>
        </Mui.ExpansionPanelDetails>
      </Mui.ExpansionPanel>
      <Mui.ExpansionPanel>
        <Mui.ExpansionPanelSummary
          onClick={fetchRestaurants}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Mui.Typography>Restaurant's List</Mui.Typography>
        </Mui.ExpansionPanelSummary>
        <Mui.ExpansionPanelDetails>
          <Mui.List className={classes.root}>
            {restaurants &&
              restaurants.length > 0 &&
              restaurants.map((restaurant, index) => (
                <>
                  <Mui.Divider />
                  <Mui.ListItem key={`listitemrestaurant${index}`}>
                    <Mui.ListItemText id="switch-list-label-wifi">
                      {`Id: ${restaurant.restaurantId} Name: ${restaurant.restaurantName} Location:${restaurant.location}`}
                    </Mui.ListItemText>
                    <Mui.ListItemSecondaryAction>
                      <Mui.Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        onClick={() => openRestaurant(restaurant.restaurantId)}
                      >
                        <ArrowRightIcon />
                      </Mui.Fab>
                    </Mui.ListItemSecondaryAction>
                  </Mui.ListItem>
                </>
              ))}
          </Mui.List>
        </Mui.ExpansionPanelDetails>
      </Mui.ExpansionPanel>
      <Mui.ExpansionPanel>
        <Mui.ExpansionPanelSummary
          onClick={fetchRestaurants}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Mui.Typography>Engagement Layer</Mui.Typography>
        </Mui.ExpansionPanelSummary>
        <Mui.ExpansionPanelDetails>
          <EngagementLayer />
        </Mui.ExpansionPanelDetails>
      </Mui.ExpansionPanel>
    </>
  );
});

export default Component.withTracker(UploadRestaurant);
