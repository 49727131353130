import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";
import * as Firebase from "firebase/app";
import "firebase/auth";

interface OrderContinueCheckoutWithCashfreeButtonProps {
  disabled: boolean;
}

const OrderContinueCheckoutWithCashfreeButton: React.FC<OrderContinueCheckoutWithCashfreeButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const toastStore = React.useContext(App.Context.Toast);

    const [user, setUser] = React.useState<Firebase.User | null>(null);

    Firebase.auth().onAuthStateChanged(user => setUser(user));

    function handleContinueCheckoutWithCashfreeClick(user: Firebase.User) {
      return async function() {
        await orderStore.requestCreateOrderWithCashfree(
          user.uid,
          user.phoneNumber as string
        );
        await orderStore.processCashfreePayment(toastStore);
      };
    }

    return (
      <>
        {!userStore.isStaff &&
          user &&
          user.phoneNumber &&
          orderStore.order &&
          (!orderStore.order.status || orderStore.order.status === "created") &&
          orderStore.isOrderEditable && (
            <Mui.Button
              variant="contained"
              fullWidth
              color="primary"
              disabled={orderStore.isOrderCreating || props.disabled}
              onClick={handleContinueCheckoutWithCashfreeClick(user)}
            >
              <Component.TwoLabel
                label1={
                  <>
                    {orderStore.isOrderCreating ? (
                      <Mui.CircularProgress />
                    ) : (
                      "Proceed to Pay"
                    )}
                  </>
                }
                label2={<Component.Price price={orderStore.total} />}
              ></Component.TwoLabel>
            </Mui.Button>
          )}
      </>
    );
  }
);

export default OrderContinueCheckoutWithCashfreeButton;
