import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as App from "../../app";

interface StaffOrdersHistoryAppBarTitleProps {}

const StaffOrdersHistoryAppBarTitle: React.FC<StaffOrdersHistoryAppBarTitleProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);

    const { pathname } = Router.useLocation();
    const isOrdersHistoryPage = pathname.includes("/history");

    return (
      <>
        {isOrdersHistoryPage && userStore.isStaff && (
          <>
            <Mui.Typography variant="h6">Orders History</Mui.Typography>
          </>
        )}
      </>
    );
  }
);

export default StaffOrdersHistoryAppBarTitle;
