/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as Container from "../../containers";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";
import * as Model from "../../models";
import * as ReactFire from "reactfire";

interface ItemProps {}

const EditItem: React.FC<ItemProps> = Mobx.observer((props) => {
  const { restaurantId } = Router.useParams<Model.RouteParams.Item>();

  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const menuStore = React.useContext(App.Context.Restaurant.Menu.Menu);
  const userStore = React.useContext(App.Context.User.User);
  const menuItemEditStore = React.useContext(App.Context.Restaurant.Menu.Item.Edit);

  const restaurantRef = ReactFire.useFirebaseApp().firestore().collection("restaurants").doc(restaurantId);
  const menuRef = restaurantRef.collection("menu");

  React.useEffect(() => {
    restaurantStore.subscribe(restaurantRef);
    menuStore.subscribe(menuRef, restaurantRef);
    return () => {
      menuItemEditStore.reset();
    };
  }, []);

  const location = Router.useLocation();

  return (
    <>
      {!userStore.isStaff && <Router.Redirect to="/" />}
      {(!menuItemEditStore.isEditable || !menuItemEditStore.item) && (
        <Router.Redirect to={`${location.pathname.split("/item/")[0]}/menu`} />
      )}
      <Styled.Page.Container>
        {userStore.isStaff && menuItemEditStore.isEditable && menuItemEditStore.item && (
          <>
            <Container.BackButton />
            <Container.StaffMenuItemEditInfo />
            <Container.StaffMenuItemAvailabilityCard />
            {/*REMOVE::PROMENU  <Container.StaffMenuItemTaxRate />
            <Container.StaffMenuItemOperatingHours /> 

            <Container.StaffMenuItemImage /> */}
            <Container.StaffMenuItemTagsCard />
            <Container.StaffMenuItemHide />
            <Container.StaffMenuItemType />
            {/*REMOVE::PROMENU  
            {menuItemEditStore.item.requiredCustomization &&
              menuItemEditStore.item.requiredCustomization.map((customization, index) => (
                <Container.MenuItemCustomizationCard
                  key={`RequiredCustomization#${index}-${customization.requiredCustomizationId}`}
                  customization={customization}
                  arrayIndex={index}
                />
              ))}
            <Container.StaffMenuItemAddRequiredCustomizationCard />
            {menuItemEditStore.item.addOnCustomization &&
              menuItemEditStore.item.addOnCustomization.map((customization, index) => (
                <Container.MenuItemCustomizationCard
                  key={`AddOnCustomization#${index}-${customization.addOnCustomizationId}`}
                  customization={customization}
                  arrayIndex={index}
                />
              ))}
            <Container.StaffMenuItemAddAddOnCustomizationCard />
            
            */}

            <Component.FixedFooter>
              <Mui.Grid container spacing={2}>
                <Mui.Grid item xs={6}>
                  <Container.StaffMenuItemEditCancelButton />
                </Mui.Grid>
                <Mui.Grid item xs={6}>
                  <Container.StaffMenuItemEditSaveButton />
                </Mui.Grid>
              </Mui.Grid>
            </Component.FixedFooter>
          </>
        )}
      </Styled.Page.Container>
    </>
  );
});

export default Component.withTracker(EditItem);
