import * as React from "react";
import * as Mui from "@material-ui/core";
import * as MuiListItem from "@material-ui/core/ListItem";

const ListItemNoPadding = Mui.styled(
  ({ button, ...props }: MuiListItem.ListItemProps) => (
    <Mui.ListItem {...props} />
  )
)(({ theme }) => ({
  padding: theme.spacing(0)
}));

export default ListItemNoPadding;
