import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import * as Mobx from "mobx-react-lite";

import Bar from "@material-ui/icons/LocalBar";
import Food from "@material-ui/icons/Fastfood";
import Restaurant from "@material-ui/icons/Restaurant";

import * as App from "../../app";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 380,
      transform: "translateZ(0px)",
      flexGrow: 1,
    },
    speedDial: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      fab: {
        color: "#000000",
        backgroundColor: "#000000",
      },
    },
    speedDialAction: {
      backgroundColor: "#000000",
      color: "#ffffff",
    },
  })
);

const actions = [
  { icon: <Food />, name: "Food" },
  { icon: <Bar />, name: "Bar" },
];

const FoodType: React.FC<any> = Mobx.observer((props) => {
  const menuStore = React.useContext(App.Context.Restaurant.Menu.Menu);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const applyFoodType = () => {
    menuStore.applyMenuType("food");
    setOpen(false);
  };
  const applyBarType = () => {
    menuStore.applyMenuType("bar");
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {menuStore.foodTypes.size > 1 && (
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          className={classes.speedDial}
          hidden={hidden}
          icon={<Restaurant />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          color={"#000000"}
          FabProps={{ style: { backgroundColor: "#000000" } }}
        >
          <SpeedDialAction
            className={classes.speedDialAction}
            icon={<Food />}
            tooltipTitle={"Food"}
            onClick={applyFoodType}
          />
          <SpeedDialAction
            className={classes.speedDialAction}
            icon={<Bar />}
            tooltipTitle={"Bar"}
            onClick={applyBarType}
          />
        </SpeedDial>
      )}
    </>
  );
});

export default FoodType;
