import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Styled from "../../styles";
import { withStyles } from "@material-ui/core/styles";

interface StaffMenuItemAvailabilityCardProps {}

const CustomSwitch = withStyles({
  switchBase: {
    color: Mui.colors.red[500],
    "&$checked": {
      color: Mui.colors.green[500]
    },
    "&$checked + $track": {
      backgroundColor: Mui.colors.green[500]
    }
  },
  checked: {},
  track: { backgroundColor: Mui.colors.red[500] }
})(Mui.Switch);

const StaffMenuItemAvailabilityCard: React.FC<StaffMenuItemAvailabilityCardProps> = Mobx.observer(props => {
  const userStore = React.useContext(App.Context.User.User);
  const menuItemStore = React.useContext(App.Context.Restaurant.Menu.Item.Item);
  const menuItemEditStore = React.useContext(App.Context.Restaurant.Menu.Item.Edit);

  function handleChange() {
    return function(event: React.ChangeEvent<HTMLInputElement>) {
      if (!menuItemEditStore.isEditable || !menuItemEditStore.item) {
        return;
      }

      menuItemEditStore.toggleAvailability();
    };
  }

  return (
    <>
      {userStore.isStaff && ((menuItemEditStore.isEditable && menuItemEditStore.item) || menuItemStore.menuItem) && (
        <>
          <Mui.ExpansionPanel expanded={true}>
            <Styled.Mui.ExpansionPanelSummary>
              <Mui.Grid container justify="space-between" alignItems="center">
                <Mui.Typography variant="h6">Availability</Mui.Typography>
              </Mui.Grid>
              <CustomSwitch
                checked={
                  menuItemEditStore.isEditable && menuItemEditStore.item
                    ? menuItemEditStore.item.available
                    : (menuItemStore.menuItem && menuItemStore.menuItem.available) || false
                }
                onChange={handleChange()}
              />
            </Styled.Mui.ExpansionPanelSummary>
          </Mui.ExpansionPanel>
        </>
      )}
    </>
  );
});

export default StaffMenuItemAvailabilityCard;
