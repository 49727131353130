import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import "firebase/auth";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "../../app";
import * as Model from "../../models";

interface RestaurantAppBarOrderButtonProps {}

const RestaurantAppBarOrderButton: React.FC<RestaurantAppBarOrderButtonProps> = Mobx.observer(
  (props) => {
    const userStore = React.useContext(App.Context.User.User);

    const { pathname } = Router.useLocation();
    const isAccountPage = pathname.endsWith("menu");

    return (
      <>
        {isAccountPage &&
          userStore.user &&
          (userStore.user as Model.User).phoneNumber && (
            <Mui.Button
              variant="outlined"
              color="inherit"
              startIcon={<Icon.Receipt />}
              component={Router.Link}
              to="/user/account"
            >
              Orders
            </Mui.Button>
          )}
      </>
    );
  }
);

export default RestaurantAppBarOrderButton;
