import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Icon from "@material-ui/icons";

interface HomeAppBarTitleProps {}

const HomeAppBarTitle: React.FC<HomeAppBarTitleProps> = Mobx.observer(props => {
  // const theme = Mui.useTheme();
  const { pathname } = Router.useLocation();
  const isHomePage = pathname === "/";

  const mallContext = React.useContext(App.Context.Mall.Mall);

  return (
    <>
      {isHomePage && mallContext.activeCity && (
        <>
          <Mui.Grid container alignItems="center">
            <Mui.Typography variant="h6">
              {mallContext.activeCity}{" "}
            </Mui.Typography>
            <Icon.KeyboardArrowRight />
          </Mui.Grid>
          {/* <Mui.Typography
            variant="body2"
            style={{ marginTop: theme.spacing(-1) }}
          >
            City
          </Mui.Typography> */}
        </>
      )}
    </>
  );
});

export default HomeAppBarTitle;
