import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Icon from "@material-ui/icons";
import * as App from "../../app";
import * as Component from "../../components";

interface RestaurantInfoProps {}

const RestaurantInfo: React.FC<RestaurantInfoProps> = Mobx.observer(props => {
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

  return (
    <>
      {restaurantStore.restaurant && (
        <Component.Info
          title={restaurantStore.restaurant.name}
          body={
            <>
              <Icon.WhereToVote />
              &nbsp;
              {restaurantStore.restaurant.address}
            </>
          }
        />
      )}
    </>
  );
});

export default RestaurantInfo;
