import * as Model from "../../../../../models";
import * as Util from "../../../../../utils";

export function item(
  menuItem: Model.Restaurant.Menu.Item.Item
): Model.Restaurant.Menu.CuratedItem {
  const addOnCustomization = Util.Curate.Restaurant.Menu.Item.Customization.addOn(
    menuItem.addOnCustomization
  );
  const requiredCustomization = Util.Curate.Restaurant.Menu.Item.Customization.required(
    menuItem.requiredCustomization
  );

  const curatedMenuItem: Model.Restaurant.Menu.CuratedItem = {
    ...menuItem,
    addOnCustomization,
    requiredCustomization
  };

  return curatedMenuItem;
}
