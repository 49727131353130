import * as Mui from "@material-ui/core";

const Fixed = Mui.styled("footer")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  maxWidth: "inherit",
  bottom: 0,
  padding: theme.spacing(2),
  marginLeft: theme.spacing(-2),
  backgroundColor: theme.palette.action.disabledBackground,
  zIndex: theme.zIndex.mobileStepper + 10
}));

export default Fixed;
