/* eslint-disable react-hooks/exhaustive-deps */
import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as React from "react";
import * as Router from "react-router-dom";
import * as App from "../../app";
import * as Component from "../../components";
import * as Container from "../../containers";

interface StaffOrderAcceptButtonProps {}

const StaffOrderAcceptButton: React.FC<StaffOrderAcceptButtonProps> = Mobx.observer(
  (props) => {
    const [
      acknowledgementDialogOpen,
      setAcknowledgementDialogOpen,
    ] = React.useState<boolean>(false);
    const [
      timeToPrepareDialogOpen,
      setTimeToPrepareDialogOpen,
    ] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const staffOrderStore = React.useContext(App.Context.Staff.Order);

    const history = Router.useHistory();

    function handleClick() {
      return async function () {
        setTimeToPrepareDialogOpen(true);
      };
    }

    async function handleTimeToPrepareDialogClose() {
      setTimeToPrepareDialogOpen(false);

      if (!orderStore.order) {
        return;
      }

      setAcknowledgementDialogOpen(true);
      setIsLoading(true);
      await staffOrderStore.acceptOrder(orderStore.order);
      setIsLoading(false);
      setTimeout(() => handleAcknowledgementDialogClose(), 2000);
    }

    function handleAcknowledgementDialogClose() {
      setAcknowledgementDialogOpen(false);
      history.goBack();
    }

    return (
      <>
        <Container.StaffOrderAcceptedAcknowledgementDialog
          open={acknowledgementDialogOpen}
          isLoading={isLoading}
          handleClose={handleAcknowledgementDialogClose}
        />
        <Component.TimeToPrepareDialog
          open={timeToPrepareDialogOpen}
          handleClose={handleTimeToPrepareDialogClose}
        />
        {userStore.isStaff &&
          orderStore.order &&
          orderStore.order.status === "created" &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId && (
            <Mui.Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleClick()}
            >
              Accept Order
            </Mui.Button>
          )}
      </>
    );
  }
);

export default StaffOrderAcceptButton;
