import * as React from "react";
import * as Mui from "@material-ui/core";

interface PageLoadingIndicatorProps {}

const PageLoadingIndicator: React.FC<PageLoadingIndicatorProps> = props => {
  return (
    <>
      <Mui.Grid
        style={{ height: "100vh" }}
        container
        justify="center"
        alignItems="center"
      >
        <Mui.CircularProgress />
      </Mui.Grid>
    </>
  );
};

export default PageLoadingIndicator;
