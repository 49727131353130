import * as Model from "../../../../../../models";
import * as Util from "../../../../../../utils";

export function required(
  requiredCustomization?: Model.Restaurant.Menu.Item.Customization.Required
): Model.Restaurant.Menu.Item.CuratedCustomization[] | undefined {
  if (!requiredCustomization) {
    return;
  }

  const requiredCustomizations = Object.keys(requiredCustomization).map(
    key => ({
      ...requiredCustomization[key],
      options: Util.Curate.Restaurant.Menu.Item.Customization.options(
        requiredCustomization[key].options
      ),
      requiredCustomizationId: key
    })
  );

  return requiredCustomizations;
}
